import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes,  } from 'react-router-dom';
import { Home } from './pages/Home';
import { Intro } from './pages/Intro';
import { CategoriesPage } from './pages/CategoriesPage';
import { Menu } from './pages/Menu';
import { ProductsPage } from './pages/ProductsPage';
import { CategoryForm } from './pages/CategoryForm';
import { ProductForm } from './pages/ProductForm';
import { VatsPage } from './pages/VatsPage';
import { VatForm } from './pages/VatForm';
import { DepartmentsPage } from './pages/DepartmentsPage';
import { DepartmentForm } from './pages/DepartmentForm';
import { OperatorsPage } from './pages/OperatorsPage';
import { OperatorForm } from './pages/OperatorForm';
import { EnterpriseForm } from './pages/EnterpriseForm';
import { FiscalPrinterForm } from './pages/FiscalPrinterForm';
import { HomeTable } from './pages/HomeTable';

const root = ReactDOM.createRoot( document.getElementById('root') );


root.render(
  <BrowserRouter basename='/'>
    <Routes>
        <Route path='/'                      element={ <Intro/> } />
        <Route path='/home'                  element={ <Home/> } />
        <Route path='/menu'                  element={ <Menu/> } />

        <Route path='/categories'            element={ <CategoriesPage/> } />
        <Route path='/categories/edit'       element={ <CategoryForm/> } />
        <Route path='/categories/edit/:id'   element={ <CategoryForm/> } />

        <Route path='/products'              element={ <ProductsPage/> } />
        <Route path='/products/edit'         element={ <ProductForm/> } />
        <Route path='/products/edit/:id'     element={ <ProductForm/> } />

        <Route path='/vats'                  element={ <VatsPage/> } />
        <Route path='/vat/edit'              element={ <VatForm/> } />
        <Route path='/vat/edit/:id'          element={ <VatForm/> } />

        <Route path='/departments'           element={ <DepartmentsPage/> } />
        <Route path='/department/edit'       element={ <DepartmentForm/> } />
        <Route path='/department/edit/:id'   element={ <DepartmentForm/> } />

        <Route path='/operators'             element={ <OperatorsPage/> } />
        <Route path='/operator/edit'         element={ <OperatorForm/> } />
        <Route path='/operator/edit/:id'     element={ <OperatorForm/> } />

        <Route path='/enterprice/edit'       element={ <EnterpriseForm/> } />
        <Route path='/fiscalprinter/edit'    element={ <FiscalPrinterForm/> } />

        <Route path='/hometable'    element={ <HomeTable/> } />
    </Routes>
  </BrowserRouter>
);

