import {HomeOutlined,
        EuroOutlined, 
        StarOutlined, 
        FolderOutlined, 
        SearchOutlined, 
        DownCircleOutlined,
        UpCircleOutlined, 
        ArrowLeftOutlined,
        DeleteOutlined, 
        GiftOutlined,
        FileOutlined, 
        CreditCardOutlined,
        RollbackOutlined,
        BackwardOutlined,
        AreaChartOutlined,
        FileSearchOutlined,
        SwapOutlined,
        AppstoreOutlined,
        DatabaseOutlined,
        UserOutlined,
        PushpinOutlined,
        HourglassOutlined,
        CloudOutlined,
        PrinterOutlined,
        SettingOutlined,
        SendOutlined,

         } from '@ant-design/icons'
import { Global, resetGlobal } from '../Global'
import { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Divider, Form, Input, Switch, Tabs } from 'antd'
import uuid from 'react-uuid'
import FormItem from 'antd/es/form/FormItem'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'


export function HomeTable () {
    const [page, setPage] = useState('favorites')
    const [pageBottom, setPageBottom] = useState('catalog')
    const [up, setUp] = useState( false )
    const [showPayments, setShowPayments] = useState( false )
    const [showModProduct, setShowModProduct] = useState( false )
    const [showModalDeleteProduct, setShowModalDeleteProduct] = useState( false )
    const [showLogOperator, setShowLogOperator] = useState( !localStorage.getItem('currentOperator')  ? true : false )
    const [modalCleanCart, setModalCleanCart] = useState( false )
    const navigate = useNavigate()

    const [productsCategory, setProductsCategory] = useState( [] )
    const [productsSearch, setproductsSearch] = useState( [] )
    const [productsFavorites, setProductsFavorites] = useState( [] )
    const [categorySelected, setCategorySelected] = useState()

    const [num1, setNum1 ] = useState('');
    const [num2, setNum2 ] = useState('');
    const [sign, setSign ] = useState('');
    const [result, setResult ] = useState( false );
    const [productsCartSelected, setProductsCartSelected ] = useState( [] );
    const [totalCart, setTotalCart] = useState( { amount: 0, quantity: 0 } )
    const [totalSelectedCart, setTotalSelectedCart] = useState( { amount: 0, quantity: 0 } )
    const [remainder, setRemainder ] = useState( 0 );

    const [showTables, setShowTables ] = useState( true );
    const [tables, setTables ] = useState( [] );

    const refTotal = useRef()
    var pin = ''
    const [form] = Form.useForm();
    const [keyForm, setKeyForm ] = useState( new Date().getDate() );
    const [currentProduct, setProductCurrent] = useState( false )
    const [isSwitch, setIsSwitch]= useState( )
    const [tabModCart, setTabModCart] = useState( currentProduct && currentProduct.discount > 0 ? 'discount' : currentProduct.increase > 0 ? 'increase' : false)

   
    if( !localStorage.getItem('jwt') ){
        resetGlobal()
        navigate('/', {replace: true})
    }

    useEffect(() => {
        opernedTables ()
        getProductsFavorites()
        calcTotalCart()
        setTables( JSON.parse( localStorage.getItem('tables') ) )
    }, [])

    
    useEffect(()=> {
        calcTotalSelectCart()
    },[ productsCartSelected ])

    useEffect(() => {
        if( !currentProduct ) return
        form.resetFields()
        form.setFieldsValue({
            'description'       : currentProduct.description,
            'quantity'          : currentProduct.quantity,
            'amount'            : ( currentProduct.prices[0].taxable + ( (currentProduct.prices[0].taxable/100) * currentProduct.vat.value) ).toFixed(2),
            'gift'              : currentProduct.gift,
            'discount'          : ( currentProduct.discount  + ( (currentProduct.discount /100) * currentProduct.vat.value) ).toFixed(2),
            'increase'          : ( ( currentProduct.increase + ( (currentProduct.increase/100) * currentProduct.vat.value) ) * parseInt( currentProduct.quantity ) ).toFixed(2)
        })
        setTabModCart( currentProduct.discount > 0 ? 'discount' : currentProduct.increase > 0 ? 'increase' : false )
        setIsSwitch( currentProduct.gift )
        setKeyForm( new Date().getTime() )
    }, [ currentProduct ])
        
    //LISTA PRODOTTI PER CATEGORIA
    async function getProducts ( category ) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("jwt", localStorage.getItem('jwt') );


        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        try{
            const response = await fetch( `${ Global.END_POINT }/api/mb/v1/product/list?page=0&record_for_page=1000000&id_category=${ category }` , requestOptions)
            if ( response.status == 200 ){
                let json = await response.json();
                if( json.Result == 'OK' ){
                    setProductsCategory( json.Json.products )
                }
            }
        }catch( e ){ 
             console.error('errore prodcat')
        }
    }
    //LISTA PRODOTTI RICERCA
    async function getProductSearch ( text ) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("jwt", localStorage.getItem('jwt') );


        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        try{
            const response = await fetch( `${ Global.END_POINT }/api/mb/v1/product/list?page=0&record_for_page=1000000&search=${ text }` , requestOptions)
            if ( response.status == 200 ){
                let json = await response.json();
                if( json.Result == 'OK' ){
                    setproductsSearch( json.Json.products )
                }
            }
        }catch( e ){ console.error('errore prodcat')}
    }
    //LiSTA PREFERITI
    async function getProductsFavorites ( ) {
        let list = JSON.parse( localStorage.getItem( 'favorites' ) )
        let favorites = []

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("jwt", localStorage.getItem('jwt') );


        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        for await( const id of list ) {
            
            try{
                const response = await fetch( `${ Global.END_POINT }/api/mb/v1/product/get_by_id?id_product=${ id }` , requestOptions)
                if ( response.status == 200 ){
                    let json = await response.json();
                    favorites.push( json )
                }
            }catch( e ){ console.error('errore prodcat')}

        };

        setProductsFavorites( favorites )
    }
   //AGGIUNGI AL CARRELLO
   function addToCart( product__ ){
    if( product__.prices.length ==  0){
        product__.prices.push(
            {
                "id": null,
                "taxable": 0,
                "discount": null,
                "with_tax_included": 1,
                "price_list" : { "id" : 255 }
            }
        )
    }
        let product = JSON.parse(JSON.stringify(product__));
        product.uuid = uuid()

        if( num1 != '' && sign =='X' && num2=='' ){
            product.quantity = parseInt( num1 )
            product.discount = 0
            product.gift = false
            product.increase = 0 
            Global.cart.products.push( product )
            cleanCalc()
            calcTotalCart()
            return
        }
        
        //MODIFICA PREZZO
        if( num1 != '' && sign == '' && num2 == ''){
            product.quantity = 1
            product.discount = 0
            product.gift = false
            product.increase = 0
            product.prices[0].taxable = num1 / parseFloat( ('1.'+( product.vat.value.toString().padStart(2,'0') )) )
            Global.cart.products.push( product )
            cleanCalc()
            calcTotalCart()
            return
        }
        product.quantity = 1
        product.discount = 0
        product.gift = false
        product.increase = 0 
        Global.cart.products.push( product )
        calcTotalCart()
   }

   //ELIMINA DAL CARRELLO
   function removeFromCart( uuid ){
    let index = Global.cart.products.findIndex( (p) => p.uuid == uuid)
    Global.cart.products.splice(index, 1)
    calcTotalCart()

    let indexSelected = productsCartSelected.findIndex( p => p.uuid == uuid)
    if( indexSelected != -1 ){
        let temp = [ ...productsCartSelected ]
        temp.splice( indexSelected, 1)
        setProductsCartSelected( temp )
    }

   } 

   //CALCOLA TOTALE CARRELLO E NUMERO PRODOTTI
   function calcTotalCart(){
    let amount = 0
    let quantity = 0

    Global.cart.products.forEach(p => {
        quantity += p.quantity
        if( !p.gift ){
            amount += ( p.prices[0].taxable + ( (p.prices[0].taxable/100) * p.vat.value) ) * parseInt(p.quantity)
            if( p.discount > 0 ){
                amount -= ( p.discount + ( (p.discount/100) * p.vat.value) ) * parseInt(p.quantity)
            }
        }

        if( p.increase > 0 ){
            amount += ( p.increase + ( ( p.increase/100 ) * p.vat.value ) ) * parseInt( p.quantity )
        }
    })
   
    setTotalCart( { amount: amount.toFixed(2), quantity: quantity } )
   }

   //SVUOTA CALCOLATRICE
   function cleanCalc(){
        setNum1( '' )
        setNum2( '' )
        setSign( '' )
        setResult( false )
   }

   //AGGIUNGI NUOVO PRODOTTO AL CARRELLO
   function addCartNewProduct( vat ){
    if( num1 == '' || sign != '' || num2 != ''){
        return
    }
    let newProduct = {
        "id": "",
        "quantity" : 1,
        'uuid' : uuid(),
        "code": "",
        "description": 'Prodotto',
        "image": "",
        "product_type": "",
        "combination": "",
        "barcode": "",
        "weight": "",
        "width": "",
        "height": "",
        "depth": "",
        "short_description": "",
        "long_description": "",
        "product_for_composition": "",
        "ultimate_cost": "",
        "role_type": "",
        "updated_at": "",
        "position": "",
        "visibile_on_market": "",
        "star_product": "",
        "easyappear_product": "",
        "id_easyappear_product": "",
        "stop_sell": "",
        "id_validus_product": "",
        "validus_additional_data": "",
        "disabled": "",
        "discount": 0,
        "gift": false,
        "increase": 0,
        "vat": {
            "id": "",
            "code": "",
            "description": "",
            "value": vat,
            "department_number": "",
            "collectability": "",
            "nature": "",
            "easyappear_vat": ""
        },
        "um": {
            "id": "",
            "code": "",
            "description": "",
            "easyappear_um": ""
        },
        "product_category": {
            "id": "",
            "reference": ""
        },
        "vat_reverse": "",
        "production_department": "",
        "prices": [
            {
                "id": "",
                "taxable": num1 / parseFloat( ('1.'+( vat.toString().padStart(2,'0') )) ),
                "discount": "",
                "with_tax_included": "",
                "price_list": {
                    "id": "",
                    "code": "",
                    "description": "",
                    "use_in_app": "",
                    "easyappear_list_price": ""
                }
            }
        ],
        "ums": [],
        "related_products": [],
        "variants": [],
        "products_categories_hierarchy": [
            {
                "id": "",
                "category": {
                    "id": "",
                    "code": "",
                    "description": "",
                    "updated_at": "",
                    "easyappear_category": "",
                    "id_easyappear_category": "",
                    "color": "",
                    "json_daily": "",
                    "json_hours": "",
                    "position": "",
                    "disabled": ""
                }
            }
        ]
    }

    Global.cart.products.push( newProduct )
    cleanCalc()
    calcTotalCart()
   }
    //SALVA MODIFICA PRODOTTO CARRELLO
    function saveChangesCart(){
           
        let index = Global.cart.products.findIndex( (p) => p.uuid == currentProduct.uuid )
        Global.cart.products[ index ].description = form.getFieldValue('description')
        Global.cart.products[ index ].quantity = parseInt( form.getFieldValue('quantity') )
        Global.cart.products[ index ].prices[0].taxable = (  parseFloat( form.getFieldValue('amount') ) / parseFloat( ('1.'+( Global.cart.products[ index ].vat.value.toString().padStart(2,'0') )) ) )
        Global.cart.products[ index ].gift = form.getFieldValue('gift')

        if( form.getFieldValue('gift') ){
            Global.cart.products[ index ].increase = 0
            Global.cart.products[ index ].discount = 0
            Global.cart.products[ index ].giftAmount = Global.cart.products[ index ].prices[0].taxable
        }else if( tabModCart == 'discount' ){
            Global.cart.products[ index ].discount = ( parseFloat( form.getFieldValue('discount') ) / parseFloat( ('1.'+( Global.cart.products[ index ].vat.value.toString().padStart(2,'0') )) ) )
            Global.cart.products[ index ].increase = 0
            Global.cart.products[ index ].discountPercentage = parseFloat( form.getFieldValue('discount') ) / ( Global.cart.products[ index ].prices[0].taxable * ( ( Global.cart.products[ index ].vat.value / 100 )+1) )
        }else if( tabModCart == 'increase' ){
            Global.cart.products[ index ].increase = ( parseFloat( form.getFieldValue('increase') ) / parseFloat( ('1.'+( Global.cart.products[ index ].vat.value.toString().padStart(2,'0') )) ) )
            Global.cart.products[ index ].discount = 0
            Global.cart.products[ index ].discountPercentage = 0
        }else if( tabModCart == false ){
            Global.cart.products[ index ].increase = 0
            Global.cart.products[ index ].discount = 0
            Global.cart.products[ index ].discountPercentage = 0
        }
        console.log( Global.cart.products[ index ] )
        calcTotalCart()
        setShowModProduct( false )

      
        let indexSelected = productsCartSelected.findIndex( p => p.uuid == currentProduct.uuid )
        console.log( indexSelected )
        console.log( productsCartSelected )
        if( indexSelected != -1 ){
            let temp = [...productsCartSelected]
            temp.splice( indexSelected, 1)
            temp.push( Global.cart.products[ index ] )
            console.log( temp )
            setProductsCartSelected( temp )
        }
    }

    //LOG OPERATORE CON PIN
    function logOperator() {

        if( !Global.superAdmin )  {
            navigate('/', {replace: true})
            return 
       }
        if( pin == Global.superAdmin.password) {
            setShowLogOperator( false )
            localStorage.setItem('currentOperator', 'admin' )
            return
        }
        let operators = JSON.parse( localStorage.getItem('operators') ).filter( o => o.access_code == pin )

        if( operators.length != 0 ){
            Global.operator = operators[0]
            localStorage.setItem('currentOperator', JSON.stringify( operators[0] ))
            setShowLogOperator( false )
            return
        }


    }

    //SELEZIONE PRODOTTI CARRELLO
    function selectProductCart( checked, product ){

        if( checked ){
            let temp = [ ...productsCartSelected ]
            temp.push( product )
            setProductsCartSelected( temp )
        }else{
            let temp = [ ...productsCartSelected ]
            let index = productsCartSelected.findIndex( pp => pp.uuid == product.uuid)
            temp.splice(index, 1)
            setProductsCartSelected( temp )
        }
        
        
    }

    //CALCOLA TOTALE PRODOTTI SELEZIONATI CARRELLO E NUMERO PRODOTTI
    function calcTotalSelectCart(){ 
        let amount = 0
        let quantity = 0
    
        productsCartSelected.forEach( p => {
            if( p == undefined ) return;
            quantity += p.quantity
            if( !p.gift ){
                amount += ( p.prices[0].taxable + ( (p.prices[0].taxable/100) * p.vat.value) ) * parseInt(p.quantity)
                if( p.discount > 0 ){
                    amount -= ( p.discount + ( (p.discount/100) * p.vat.value) ) * parseInt(p.quantity)
                }
            }

            if( p.increase > 0 ){
                amount += ( p.increase + ( ( p.increase/100 ) * p.vat.value ) ) * parseInt( p.quantity )
            }
        })
        setTotalSelectedCart( { amount: amount.toFixed(2), quantity: quantity } )
    }

     //CALCOLO RESTO
     function calcRemainder( value ){
       if( value == '' ) value = 0
        if( productsCartSelected.length > 0 ){
            setRemainder( value - totalSelectedCart.amount  )
            return
        }
            setRemainder( value - totalCart.amount  )
    }

    //RICERCA TAVOLI
    function filterTables ( value ){
        let temp = JSON.parse( localStorage.getItem('tables') ) 
        let filtered = temp.filter( t => t.description.toLowerCase().includes( value.toLowerCase() ) )
        setTables( filtered )
    }

    //TAVOLI APERTI
   async function opernedTables () {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("jwt", Global.jwt );
        
        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
 
        try{
            const response = await fetch( `${ Global.END_POINT }/api/mb/v1/easyappear_connector/get_opened_order_table` , requestOptions)
            if ( response.status == 200 ){
                let json = await response.json();
                if( json.Result == 'OK' ){
                   localStorage.setItem('OpenedTables', JSON.stringify( json.Json.orders ))
                    let tables = JSON.parse( localStorage.getItem('tables') )
                    tables.map(t => {
                        json.Json.orders.forEach(ot => {
                            if( t.code == ot.table ) {
                                t.cart = ot.products
                                t.opened = true
                            }
                        });
                    })
                    localStorage.setItem( 'tables', JSON.stringify( tables ) )
                }
            }
        }catch( e ){ console.error('errore tavoli aperti')}
    }

    //INVIO SCONTRINO
    function sendingReceipt( type ){

        if( type.payment_type == 'MP01' ){
            if( productsCartSelected.length > 0 ){
                let temp = [...productsCartSelected ]
                temp.forEach( e => {
                    let index = Global.cart.products.findIndex( g => e.uuid == g.uuid )
                    if( index != -1 ){
                        Global.cart.products.splice( index, 1 )
                    }
                })
                setProductsCartSelected( [] )
                calcTotalCart()
                calcTotalSelectCart()
                setShowPayments( false )
                setRemainder(0)
                return
            }else{
                Global.cart.products = []
                calcTotalCart()
                setShowPayments( false )
                setProductsCartSelected( [] )

                calcTotalCart()
                calcTotalSelectCart()
                setShowPayments( false )
            }


            setProductsCartSelected( [] )
            calcTotalCart()
            calcTotalSelectCart()
            setShowPayments( false )
        }

    }

     //INVIO AI REPARTI DI PRODUZIONE
     function sendingDepartments(){
        
     }

     //SETTAGGIO TEMA 1
    function getColorTheme1() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor1, color: Global.darkColor.textColor1  }
        }else{
            return {   }
        }
    }
    //SETTAGGIO TEMA 2
    function getColorTheme2() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor2, color: Global.darkColor.textColor1  }
        }else{
            return { backgroundColor: Global.lightColor.bgColor1, color: Global.lightColor.textColor1  }
        }
    }

    return(
        <>
            <div className="bg-gradient" style={{ backgroundImage:'unset', display:'flex', flexDirection:'column',...getColorTheme1()}}>
            {                                                          /* TAVOLI */                                                                                       }
            <div className='tables-page'  style={ !showTables ? { display:'none' } : {...getColorTheme1()}}>
                <Input key={'inputTables'} onChange={( e ) => filterTables ( e.target.value )} placeholder='Cerca tavolo' style={{position:'absolute', top: '3dvh', width:'90dvw', height: '5dvh', left:'5dvw'}}></Input>
                {
                    tables.map( t => <div onClick={() => {
                        setShowTables( false )
                        if( t.opened ){
                            localStorage.setItem('currentTableCart', JSON.stringify( t.cart ))
                        }else{
                            localStorage.setItem('currentTableCart', JSON.stringify( [] ))
                        }
                        localStorage.setItem('currentTable', JSON.stringify( t ))
                    }} key={ t.id + t.description + new Date().getDate() } style={ t.opened ? { backgroundColor: 'orange' } : {}} className='table'>{ t.description }</div>)
                }
            </div> 

            {                                                          /* LOG OPERATOR */                                                                                       } 
                <div style={showLogOperator ? {display:'flex', justifyContent:'center', alignItems:'center', position: 'absolute', top:'0', left:'0', width:'100dvw', height:'100dvh', background: Global.colorBgOne, zIndex:'100', ...getColorTheme1()} : {display:'none'}}>
                    <div style={{ width:'70%', height:'30%'}}>
                        <div style={{ textAlign:'center', color:'white', fontSize:'1.8em', marginBottom:'15px'}}>Operatore</div>
                        <div style={{ textAlign:'center', color:'white', fontSize:'0.8em', marginBottom:'15px'}}>{'( Se sei l`admin reinserisci la password )'}</div>
                        <Input onChange={( e ) => pin = e.target.value} type='password' style={{ marginBottom:'15px', }} className="input" placeholder='Pin'></Input>
                        <Button className="btn" onClick={()=> logOperator()} style={{ color:Global.colorBgOne, ...getColorTheme1()  }}>Accedi</Button>
                    </div>
                </div>
                <div className="top-bar" style={{ ...getColorTheme2()}}>
                    <div onClick={()=> navigate('/home')} style={{ height:'100%', width:'30%', alignItems:'center', display:'flex', fontSize:'20px', color: Global.colorBgOne}}> 
                        <ArrowLeftOutlined></ArrowLeftOutlined> CASSA
                    </div>
                    <div style={{ width: '40%',  textAlign:'center'}}>
                        {
                          showTables ? 'Tavoli' :  localStorage.getItem('currentTable') ? 'Tavolo:'+' '+JSON.parse( localStorage.getItem('currentTable') ).description : ''
                        }
                    </div>
                    <div style={{ display:'flex'}}>
                        <div onClick={() => { setShowTables( !showTables ) }} className='colorPng' style={{ width:'50%', height:'100%', fontSize:'30px', justifyContent:'center', alignItems:'center', display:'flex',}} >
                            <img style={{ width:'40px', marginRight:'15px', display:''}} src='https://static.thenounproject.com/png/2824039-200.png'></img>
                        </div>
                    </div>
                </div>

{                                                          /* CONTAINER PAGINE */                                                                                       }  
                <div style={{ display:'flex', position:'relative', height:'100%', backgroundColor:'', width:'100%',...getColorTheme1() }}>
                
{                                                         /* PAGINA CATALOGO */                                                                                       }  
                    <div className={ pageBottom == 'cart' ? 'page-catalog-out' : 'page-catalog'  } style={{...getColorTheme2()}}>
                        <div className="sub-top-bar" >
                            <div 
                                className={ page!='favorites' ? 'text-sub-top-bar' : 'text-sub-top-bar-selected'} 
                                style={{ display:'flex', justifyContent:'center', alignItems:'center', width:'40%',...getColorTheme1()}}
                                onClick={() => { setPage('favorites')}}
                                >
                                    <div style={{marginRight:'5px', fontSize:'18px',...getColorTheme1() }}> <StarOutlined /></div>
                                    <div style={{ fontSize:'18px',...getColorTheme1() }}>Preferiti</div>
                            </div>
                            <div 
                                className={ page!='categories' ? 'text-sub-top-bar' : 'text-sub-top-bar-selected'} 
                                style={{ display:'flex', justifyContent:'center', alignItems:'center', width:'40%',...getColorTheme1()}}
                                onClick={() => { setPage('categories')}}
                                >
                                    <div style={{marginRight:'5px', fontSize:'18px'}}> <FolderOutlined /> </div>
                                    <div style={{fontSize:'18px' }}>Categorie</div>
                            </div>
                            <div 
                                className={ page!='search' ? 'text-sub-top-bar' : 'text-sub-top-bar-selected'} 
                                style={{ display:'flex', justifyContent:'center', alignItems:'center', width:'20%',...getColorTheme1()}}
                                onClick={() => { setPage('search')}}
                                >
                                    <div style={{marginRight:'5px',  fontSize:'18px',...getColorTheme1()}}> <SearchOutlined style={{...getColorTheme1()}}/> </div>
                            </div>
                        </div>

                        <div className='container' style={page == 'categories' ? { overflowY:'scroll', top:'21dvh', height:'65dvh', paddingTop:'8px',...getColorTheme2()} : { overflowY:'scroll',...getColorTheme2() }}>
                            {
                                page == 'categories'
                                ?
                                productsCategory.map(p => {
                                return <div
                                        key={p.id+p.code}
                                        onClick={(e) => {
                                            refTotal.current.className += ' animation-category-box'
                                            e.currentTarget.className += ' animation-category-box'
                                            addToCart( p )
                                        }}
                                        onAnimationEnd={(e) => {
                                            e.currentTarget.className = 'box-category'
                                            refTotal.current.className = 'text-bottom-bar'
                                        }}
                                        className='box-category' style={{ fontSize:'0.9em', padding:'20px', position:'relative',...getColorTheme1()}}>
                                            { <div style={{position:'absolute', top:'10px', left: '20px', fontWeight:'bold'}}>{p.description}</div> }
                                            { <div style={{position:'absolute', bottom:'10px', right: '10px'}}>{ p.prices[0] ? (p.prices[0].taxable + ( (p.prices[0].taxable/100) * p.vat.value) ).toFixed(2)+' €' : '0€'}</div> } 
                                        <div style={{ height:'100%', width:'10px', position:'absolute',borderRadius:'20px 0px 0px 20px', backgroundColor: p.products_categories_hierarchy.length > 0 ? p.products_categories_hierarchy[0].category.color ?? '' : '', left:'0' }}></div> 
                                </div>
                            
                            })
                                :
                                page == 'favorites'
                                ?
                                productsFavorites.map(p => {
                                    return <div
                                            key={p.id+p.code}
                                            onClick={(e) => {
                                                refTotal.current.className += ' animation-category-box'
                                                e.currentTarget.className += ' animation-category-box'
                                                addToCart( {...p} ) 
                                            }}
                                            onAnimationEnd={(e) => {
                                                e.currentTarget.className = 'box-category'
                                                refTotal.current.className = 'text-bottom-bar'
                                            }}
                                            className='box-category' style={{ fontSize:'0.9em', padding:'20px', position:'relative',...getColorTheme1()}}>
                                         { <div style={{position:'absolute', top:'10px', left: '20px', fontWeight:'bold'}}>{p.description}</div> }
                                         { <div style={{position:'absolute', bottom:'10px', right: '10px'}}>{ p.prices[0] ? ( p.prices[0].taxable + ( (p.prices[0].taxable/100) * p.vat.value) ).toFixed(2)+' €' : '0€'}</div> }  
                                         <div style={{ height:'100%', width:'10px', position:'absolute', backgroundColor: 'yellow', left:'0',borderRadius:'20px 0px 0px 20px' }}></div> 
                                    </div>
                                })
                                :
                                page == 'search'
                                ?
                                <>
                                <Form onSubmitCapture={() => { return }}>
                                <Input
                                    prefix={ <SearchOutlined style={{ color: Global.colorBgOne,}}/> }
                                    onChange={ (value) => {
                                        if( value.target.value.length < 3 ) setproductsSearch([])
                                        if( value.target.value.length < 3 ) return
                                        getProductSearch(value.target.value)
                                        }} style={{ position:'absolute', top: '3dvh', width:'95dvw', left:'2dvw', marginTop:'10px', height:'10%', display:'flex', fontSize:'1.5em'}}></Input>
                                </Form>
                                        <div className=""></div>
                                    { productsSearch.map(p => {
                                        return <div
                                                key={p.id+p.code}
                                                onClick={(e) => {
                                                    refTotal.current.className += ' animation-category-box'
                                                    e.currentTarget.className += ' animation-category-box' 
                                                    addToCart( p ) 
                                                }}
                                                onAnimationEnd={(e) => {
                                                    e.currentTarget.className = 'box-category'
                                                    refTotal.current.className = 'text-bottom-bar'
                                                }} 
                                                className='box-category' style={{ fontSize:'0.9em', padding:'20px', position:'relative',...getColorTheme1()}}>
                                                { <div style={{position:'absolute', top:'10px', left: '20px', fontWeight:'bold'}}>{p.description}</div> }
                                                { <div style={{position:'absolute', bottom:'10px', right: '10px'}}>{p.prices[0] ? ( p.prices[0].taxable + ( (p.prices[0].taxable/100) * p.vat.value) ).toFixed(2)+' €' : '0€'}</div> }  
                                            <div style={{ height:'100%', width:'10px', position:'absolute', backgroundColor: p.products_categories_hierarchy.length > 0 ?  p.products_categories_hierarchy[0].category.color ?? 'grey' : 'grey' , left:'0',borderRadius:'20px 0px 0px 20px' }}></div> 
                                        </div>
                                    })}
                                </>
                                :
                                <></>
                            }
                        </div>

                        <div className={ up ? 'calc' : 'calc-down'} >
                            <div className='barCalc'>
                                <div style={{ width:'75%', height:'40px', display:'flex', flexDirection:'column', justifyContent:'center', paddingLeft:'10px', fontSize:'25px',...getColorTheme2()}}>
                                  { 
                                    !result ? 
                                        <div style={{display:'flex', gap: '3px', color:Global.colorBgOne}}>
                                            <div>{num1}</div>
                                            <div>{sign}</div>
                                            <div>{num2}</div>
                                        </div> 
                                    :
                                    <div>{result}</div>
                                    }
                                </div>
                                <div  
                                    style={{ width:'25%', display:'flex', alignItems:'center', justifyContent:'center', fontSize:'20px', color:Global.colorBgOne, backgroundColor: 'aliceblue',...getColorTheme2() }}
                                    onClick={()=>{ setUp(!up) }}
                                >
                                { !up ?
                                        <UpCircleOutlined />
                                    :
                                        <DownCircleOutlined />
                                    }
                                </div>
                            </div>
                            {                             /* PULSANTI CALCOLATRICE */                                                                              } 
                            <div style={{ width:'100%', display:'flex', height:'45dvh',...getColorTheme1()}}>
                                <div className='calc-bottom' style={{...getColorTheme1()}}>
                                    <div className='num'
                                        onClick={() => {
                                            if( sign ){
                                                if( num2.length == 1 && num2[0] == '0' ){
                                                    setNum2( '7' )
                                                    return
                                                }
                                                setNum2(num2+ '7' )
                                                return
                                            }
                                            if( num1.length == 1 && num1[0] == '0' ){
                                                setNum1( '7' )
                                                return
                                            }
                                            setNum1( num1+'7' )
                                        }} 
                                    >7</div>
                                    <div className='num'
                                        onClick={() => {
                                            if( sign ){
                                                if( num2.length == 1 && num2[0] == '0' ){
                                                    setNum2( '8' )
                                                    return
                                                }
                                                setNum2(num2+ '8' )
                                                return
                                            }
                                            if( num1.length == 1 && num1[0] == '0' ){
                                                setNum1( '8' )
                                                return
                                            }
                                            setNum1( num1+'8' )
                                        }} 
                                    >8</div>
                                    <div className='num'
                                        onClick={() => {
                                            if( sign ){
                                                if( num2.length == 1 && num2[0] == '0' ){
                                                    setNum2( '9' )
                                                    return
                                                }
                                                setNum2(num2+ '9' )
                                                return
                                            }
                                            if( num1.length == 1 && num1[0] == '0' ){
                                                setNum1( '9' )
                                                return
                                            }
                                            setNum1( num1+'9' )                    
                                        }} 
                                    >9</div>
                                    <div className='num'
                                        onClick={() => {
                                          if( num1 ){
                                            if( num1[ num1.length -1 ] == '.' ){
                                                setNum1( num1.replace('.',''))
                                            }
                                            setSign('X')
                                          }     
                                        }} 
                                    > X </div>
                                    
                                    <div className='num'
                                        onClick={() => {
                                            if( sign ){
                                                if( num2.length == 1 && num2[0] == '0' ){
                                                    setNum2( '4' )
                                                    return
                                                }
                                                setNum2(num2+ '4' )
                                                return
                                            }
                                            if( num1.length == 1 && num1[0] == '0' ){
                                                setNum1( '4' )
                                                return
                                            }
                                            setNum1( num1+'4' )
                                        }} 
                                    >4</div>
                                    <div className='num'
                                        onClick={() => {
                                            if( sign ){
                                                if( num2.length == 1 && num2[0] == '0' ){
                                                    setNum2( '5' )
                                                    return
                                                }
                                                setNum2(num2+ '5' )
                                                return
                                            }
                                            if( num1.length == 1 && num1[0] == '0' ){
                                                setNum1( '5' )
                                                return
                                            }
                                            setNum1( num1+'5' )
                                        }} 
                                    >5</div>
                                    <div className='num'
                                        onClick={() => {
                                            if( sign ){
                                                if( num2.length == 1 && num2[0] == '0' ){
                                                    setNum2( '6' )
                                                    return
                                                }
                                                setNum2(num2+ '6' )
                                                return
                                            }
                                            if( num1.length == 1 && num1[0] == '0' ){
                                                setNum1( '6' )
                                                return
                                            }
                                            setNum1( num1+'6' )
                                        }} 
                                    >6</div>
                                    <div className='num'
                                        onClick={() => { 
                                            if( num1 ){
                                                setSign('+%')
                                              }  
                                        }} 
                                    >+%</div>

                                    <div className='num'
                                        onClick={() => { 
                                            if( sign ){
                                                if( num2.length == 1 && num2[0] == '0' ){
                                                    setNum2( '1' )
                                                    return
                                                }
                                                setNum2(num2+ '1' )
                                                return
                                            }
                                            if( num1.length == 1 && num1[0] == '0' ){
                                                setNum1( '1' )
                                                return
                                            }
                                            setNum1( num1+'1' )
                                        }} 
                                    >1</div>
                                    <div className='num'
                                        onClick={() => {
                                            if( sign ){
                                                if( num2.length == 1 && num2[0] == '0' ){
                                                    setNum2( '2' )
                                                    return
                                                }
                                                setNum2(num2+ '2' )
                                                return
                                            }
                                            if( num1.length == 1 && num1[0] == '0' ){
                                                setNum1( '2' )
                                                return
                                            }
                                            setNum1( num1+'2' )
                                        }} 
                                    >2</div>
                                    <div className='num'
                                        onClick={() => {
                                            if( sign ){
                                                if( num2.length == 1 && num2[0] == '0' ){
                                                    setNum2( '3' )
                                                    return
                                                }
                                                setNum2(num2+ '3' )
                                                return
                                            }
                                            if( num1.length == 1 && num1[0] == '0' ){
                                                setNum1( '3' )
                                                return
                                            }
                                            setNum1( num1+'3' )
                                        }} 
                                    >3</div>
                                    <div className='num'
                                        onClick={() => { 
                                            if( num1 ){
                                                setSign('-%')
                                              }  
                                        }} 
                                    >-%</div>

                                    <div className='num'
                                        onClick={() => { 
                                            if( sign ){
                                                if( num2.length >= 1 && !num2.includes('.')){
                                                    setNum2( num2+'.')
                                                }
                                                return
                                            }
                                            if( num1.length >= 1 && !num1.includes('.')){
                                                setNum1( num1+'.')
                                            }
                                        }} 
                                    >,</div>
                                    <div className='num'
                                        onClick={() => {
                                            if( sign ){
                                                if( num2[ 0 ] == '0' && num2.length == 1 ){
                                                    return
                                                 }
                                                setNum2( num2+'0' )
                                                return
                                            }
                                            if( num1[ 0 ] == '0' && num1.length == 1 ){
                                               return
                                            }
                                            setNum1( num1+'0' )
                                        }}
                                    >0</div>
                                    <div className='num'
                                        onClick={() => { 
                                            if( sign ){
                                                if( num2[ 0 ] == '0' && num2.length == 1 || num2.length == 0 ){
                                                    return
                                                 }
                                                setNum2( num2+'00' )
                                                return
                                            }
                                            if( num1[ 0 ] == '0' && num1.length == 1 || num1.length == 0 ){
                                               return
                                            }
                                            setNum1( num1+'00' )
                                        }} 
                                    >00</div>
                                    <div className='num'
                                        onClick={() => { 
                                            cleanCalc()
                                        }} 
                                    >C</div>
                                </div>
                                <div className='bottom-right' >
                                   {
                                    JSON.parse( localStorage.getItem('vats') ).map( v => {
                                        return <div key={v.id+v.value} style={{ ...getColorTheme2() }} onClick={() => { addCartNewProduct( v.value ) }} className='vat'>{ v.value+'%' }</div> 
                                    })
                                   }
                                </div>
                            </div>

                        </div>
                        {                             /* SCROLL BAR CATEGORIE */                                                                              }  
                        <div className='categories-scroll' style={{ display: page != 'categories' ? 'none' : '', color: Global.colorBgOne, ...getColorTheme2()}}>
                            {
                                JSON.parse( localStorage.getItem('categories') ).map( (c) =>  {
                                    return <div key={c.code+c.id} style={ categorySelected == c.id ? { backgroundColor: Global.colorBgOne, color:'white',...getColorTheme2() } : {textAlign:'center',...getColorTheme1()} } className="btn-category-scroll" onClick={() =>{     
                                        getProducts( c.id ) 
                                        setCategorySelected( c.id )
                                    }} >
                                   <div key={c.code+c.id+'bar'} style={{ width:'100%', height:'5px', position:'absolute', top:'0', left:'0',backgroundColor: c.color,}}></div>
                                    { c.description }
                                </div>}
                                )
                            }         
                        </div>
                    </div>

{                                                     /* PAGINA CARRELLO */                                                                                        }  
                    <div className={ pageBottom == 'cart' ? 'page-cart-in' : 'page-cart'  }>

                    {                                /* PAGINA MODIFICA PRODOTTO CARRELLO */             }  
                        <div key={ keyForm } className={showModProduct ? 'page-mod-product-in-cart' : 'page-mod-product-in-cart-hide'} style={{...getColorTheme2()}}>
                            <div className='mod-product-cart-top-bar'>
                                <div style={{ color:Global.colorBgOne, ...getColorTheme2() }}> Modifica voce</div>
                                <div style={{display:'flex', gap: '20px'}}>
                                    <div onClick={() => { setProductCurrent( false ); setShowModProduct( false )}} style={{ color:Global.colorBgTwo, ...getColorTheme2() }}>ANNULLA</div>
                                    <div onClick={() => { saveChangesCart()}} style={{ color:Global.colorBgTwo, ...getColorTheme2()}}>SALVA</div>
                                </div>
                            </div>
                            <div className="content-page-mod-product-cart" style={{...getColorTheme1()}}>
                            <div style={{ fontSize:'20px', padding:'20px',...getColorTheme1()}}>Voce scontrino</div>
                                <Form  form={ form } style={{ ...getColorTheme1() }}>
                                    <Form.Item style={{ margin: '0', flexDirection:'row', ...getColorTheme1()}} name={ 'description' }>
                                         <div className='borderBottom' style={{ display:'flex', justifyContent:'start',...getColorTheme1()}}>
                                            <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%',...getColorTheme1()  }}>Descrizione:</div>
                                            <input style={{...getColorTheme1()}} placeholder='Inserisci la descrizione' defaultValue={ form.getFieldValue('description') } className='input-mod'></input>
                                        </div>
                                    </Form.Item >
                                    <Form.Item style={{ margin: '0'}} name={ 'quantity' }> 
                                        <div className='borderBottom'style={{ display:'flex'}}>
                                            <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px',...getColorTheme1() }}>Quantità:</div>
                                            <input style={{...getColorTheme1()}}placeholder='Inserisci la quantità' defaultValue={ form.getFieldValue('quantity') } className='input-mod'></input>
                                        </div>
                                    </Form.Item>
                                    <Form.Item style={{ margin: '0'}} name={ 'amount' }> 
                                        <div className='borderBottom'style={{ display:'flex'}}>
                                            <div style={{ textJustify:'center',margin:'auto 0px auto 15px', marginLeft:'10px', ...getColorTheme1() }}>Prezzo unitario:</div>
                                            <input style={{ ...getColorTheme1() }} placeholder='Inserisci il prezzo unitario' type='number' defaultValue={ form.getFieldValue('amount') } className='input-mod'></input>
                                        </div>
                                    </Form.Item>
                                    
                                        <div className='borderBottom'style={{ display:'flex', justifyContent:'space-between'}}>
                                            <div style={{ textJustify:'center',margin:'auto 0px auto 15px', marginLeft:'10px',  }}>Omaggio:</div>
                                            <Form.Item style={{ margin: '0'}} name={ 'gift' }>
                                                <Switch style={{ marginRight:'20px',...getColorTheme1()}} defaultChecked={ isSwitch ? true : false } onChange={( e ) => {
                                                    setTabModCart( false )
                                                    setIsSwitch( e )
                                                }} >
                                                </Switch>
                                            </Form.Item>
                                        </div>
                                       
                                            <div style={{ fontSize:'20px', padding:'20px' , display: isSwitch ? 'none' : ''}}>Alterazioni</div>
                                            <Tabs onChange={( e ) => setTabModCart( e ) } style={{ width:'100%', justifyContent:'center', display: isSwitch ? 'none' : '', ...getColorTheme1()}} defaultActiveKey={tabModCart ?? false} items={[
                                                {label: 'Nessuna', key:false, },
                                                {label: 'Sconto', key:'discount', },
                                                {label: 'Maggiorazione', key:'increase', },
                                                ]}>
                                            </Tabs>

                                        {
                                            tabModCart == 'discount'  ?
                                                <Form.Item style={{ margin: '0',...getColorTheme1()}} name={ 'discount' } dependencies={['amount']} rules={[
                                                    ({ getFieldValue }) => ({
                                                      validator(_, value) {
                                                        if (!value || parseFloat ( getFieldValue('amount') ) >= parseFloat( value )) {
                                                          return Promise.resolve();
                                                        }
                                                        return Promise.reject( new Error('Lo sconto deve essere minore del prezzo.') );
                                                      },
                                                    }),
                                                  ]}>
                                                    <div className='borderBottom'style={{ display:'flex'}}>
                                                        <div style={{ textJustify:'center',margin:'auto 0px auto 15px', marginLeft:'10px',...getColorTheme1()  }}>Sconto unitario:</div>
                                                        <input style={{ ...getColorTheme1()}} placeholder="Inserisci l'importo" type='number' defaultValue={ currentProduct ? ( currentProduct.discount  + ( ( currentProduct.discount / 100 ) * currentProduct.vat.value) ).toFixed(2) : 0  } className='input-mod'></input>
                                                    </div>
                                                </Form.Item>
                                            :
                                            tabModCart == 'increase'
                                            ?
                                                <Form.Item style={{ margin: '0',...getColorTheme1()}} name={ 'increase' }>
                                                    <div className='borderBottom'style={{ display:'flex'}}>
                                                        <div style={{ textJustify:'center',margin:'auto 0px auto 15px', marginLeft:'10px',  }}>Maggiorazione unitaria:</div>
                                                        <input style={{ ...getColorTheme1() }} placeholder="Inserisci l'importo" type='number' defaultValue={ currentProduct ? ( currentProduct.increase  + ( ( currentProduct.increase / 100 ) * currentProduct.vat.value) ).toFixed(2) : 0 } className='input-mod'></input>
                                                    </div>
                                                </Form.Item>
                                            : <></>
                                            }
                                            
                                </Form>
                                
                            </div>
                            <div className="page-mod-product-cart-bottom-bar">
                                <div onClick={() => { setShowModalDeleteProduct( true )}} className='btn-delete' ><div>ELIMINA</div></div>                                
                            </div>

                            {          /* MODALE ELIMINAZIONE PRODOTTO */             }
                            <div onClick={( e ) => {
                                    if( e.target.className != 'modal-delete-product' ) return;
                                        setShowModalDeleteProduct( false )
                                }} className={ showModalDeleteProduct ? 'modal-delete-product' : 'modal-delete-product-hide' }>
                                <div style={{ width:'65%', height:'22%', backgroundColor: Global.colorGrey, boxShadow:'5px 5px 16px 5px rgba(0,0,0,0.22)', borderRadius:'5px', padding:'20px'}}>
                                    <div style={{ marginBottom:'20px'}}>Elimina</div>
                                    <div style={{ }}>Sicuro di voler procedere?</div>
                                    <div style={{ width:'100%', backgroundColor:'', position:'relative', bottom:'-50px', height:'30px', display:'flex', justifyContent:'space-between', gap:'2px'}}>
                                        <div style={{ color: Global.colorBgOne }} onClick={() => { setShowModalDeleteProduct( false )}}>ANNULLA</div>
                                        <div onClick={() => {
                                            removeFromCart( currentProduct.uuid )
                                            setShowModalDeleteProduct( false )
                                            setShowModProduct( false )
                                            }} style={{ color: Global.colorBgOne }}>PROCEDI</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cart-content" style={{display: showPayments ? 'none' : '', color: Global.colorBgOne}}>
                            {
                                JSON.parse( localStorage.getItem('currentTableCart') ).map( p =>{ return <div 
                                key={p.uuid+p.id}
                                onClick={(e) => {
                                    if (e.target.tagName == 'INPUT') return
                                    setProductCurrent( p )
                                    setShowModProduct( true )
                                }}
                                className='cart-product'>
                                    <Checkbox onClick={( e )=> {
                                        selectProductCart( e.target.checked, p )
                                        }}style={{ width: '10%', marginLeft:'10px'}}>
                                    </Checkbox>

                                    <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center',width:'100%', height:'100%', padding:'0px 25px 0px 25px'}}>
                                        <div style={{ width:'55%' ,justifyContent:'',height:'100%', display:'flex', flexDirection:'column', alignItems:'start'}}>
                                            <div style={{ fontWeight:'bold'}}>{ p.description }</div>
                                            { 
                                                p.gift ?
                                                    <div sty>{'Omaggio'}</div>
                                                : p.discount > 0 ?
                                                    <div>{'Sconto'}</div>
                                                : p.increase > 0 ?
                                                    <div>{'Maggiorazione'}</div>
                                                : <></>
                                            }
                                            <div>{ p.vat.value+'%'}</div>
                                        </div>
                                        <div style={{ width:'10%',height:'100%', display:'flex' , alignItems:'start'}}>{ p.quantity+'X'}</div>
                                        <div style={{ width:'15%' , height:'100%', display:'flex',justifyContent:'', flexDirection:'column', alignItems:'end',}}>
                                            <div style={{ fontWeight:'bold' }}>{p.prices[0] ? (( p.prices[0].taxable + ( (p.prices[0].taxable/100) * p.vat.value) ) * parseInt(p.quantity)).toFixed(2) + '€' : '0€'}</div>
                                            { 
                                                p.gift ?
                                                    <div sty>{ p.prices[0] ? '-'+(( p.prices[0].taxable + ( ( p.prices[0].taxable/100 ) * p.vat.value) ) * parseInt(p.quantity)).toFixed(2) + '€' : '0€'}</div>
                                                : p.discount > 0 ?
                                                    <div>{'-'+(( p.discount + ( (p.discount/100) * p.vat.value) ) * parseInt( p.quantity ) ).toFixed(2) + '€' }</div>
                                                : p.increase > 0 ?
                                                    <div>{ '+'+( ( p.increase + ( (p.increase/100) * p.vat.value) ) * parseInt( p.quantity) ).toFixed(2) + '€' }</div>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ width:'100%', position:'absolute', bottom:'0px', }}><Divider style={{ margin: 0}}></Divider></div>
                                </div>})
                            }
                        </div>
                        <div className='payments-page' style={{ display: !showPayments ? 'none' : '',...getColorTheme1() }}>
                            <div className="sub-top-bar" style={{ ...getColorTheme1() }} >
                                    <div
                                        onClick={() => setShowPayments( false )}
                                        className={ 'text-sub-top-bar' } 
                                        style={{ display:'flex', justifyContent:'center', alignItems:'center', width:'50%',...getColorTheme1()}}
                                        >
                                            <div style={{marginRight:'5px', fontSize:'18px', color:'red' }}> <RollbackOutlined /></div>
                                            <div style={{ fontSize:'15px' }}>Annulla Pagamento</div>
                                    </div>
                            </div>
                            {                               /* CONTENUTO PAGINA PAGAMENTO */                                                 }
                            <div className='containerPayments'>
                            {
                                ( productsCartSelected.length > 0 ? productsCartSelected : Global.cart.products ).map( p =>{ return <div 
                                key={p.uuid+p.id}
                                className='cart-product'>
                                    <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center',width:'100%', height:'100%', padding:'0px 25px 0px 25px'}}>
                                        <div style={{ width:'60%' ,justifyContent:'',height:'100%', display:'flex', flexDirection:'column', alignItems:'start'}}>
                                            <div style={{ fontWeight:'bold'}}>{ p.description }</div>
                                            { 
                                                p.gift ?
                                                    <div sty>{'Omaggio'}</div>
                                                : p.discount > 0 ?
                                                    <div>{'Sconto'}</div>
                                                : p.increase > 0 ?
                                                    <div>{'Maggiorazione'}</div>
                                                : <></>
                                            }
                                            <div>{ p.vat.value+'%'}</div>
                                        </div>
                                        <div style={{ width:'10%',height:'100%', display:'flex' , alignItems:'start'}}>{ p.quantity+'X'}</div>
                                        <div style={{ width:'15%' , height:'100%', display:'flex',justifyContent:'', flexDirection:'column', alignItems:'end',}}>
                                            <div style={{ fontWeight:'bold' }}>{p.prices[0] ? (( p.prices[0].taxable + ( (p.prices[0].taxable/100) * p.vat.value) ) * parseInt(p.quantity)).toFixed(2) + '€' : '0€'}</div>
                                            { 
                                                p.gift ?
                                                    <div sty>{ p.prices[0] ? '-'+(( p.prices[0].taxable + ( (p.prices[0].taxable/100) * p.vat.value) ) * parseInt(p.quantity)).toFixed(2) + '€' : '0€'}</div>
                                                : p.discount > 0 ?
                                                    <div>{'-'+(( p.discount + ( (p.discount/100) * p.vat.value) ) * parseInt( p.quantity ) ).toFixed(2) + '€' }</div>
                                                : p.increase > 0 ?
                                                    <div>{ '+'+( ( p.increase + ( (p.increase/100) * p.vat.value) ) * parseInt( p.quantity) ).toFixed(2) + '€' }</div>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ width:'100%', position:'absolute', bottom:'0px', }}><Divider style={{ margin: 0}}></Divider></div>

                                </div>})
                            }
                            </div>

                            <div className='bottom-page-payments' style={{ ...getColorTheme2() }}>
                                <div 
                                    className='button-payment'
                                    style={{backgroundColor:'white', color: Global.colorBgOne, position:'unset',...getColorTheme2()}}
                                    >
                                        <CreditCardOutlined /> Pagamenti 
                                </div>
                                <div style={{ width:'100%', textAlign: 'center', color:Global.colorBgOne, marginBottom:'10px',...getColorTheme2()}}> Da pagare { productsCartSelected.length >  0 ? `${totalSelectedCart.amount}€ di ${totalCart.amount}€` : `${totalCart.amount}€` } </div>
                                <Divider style={{margin:'0px'}}></Divider>
                                <div style={{ display:'flex', width:'100%', justifyContent:'center', fontSize:'25px', fontWeight:'bold',...getColorTheme2() }}>
                                    <Input
                                    onChange={( value ) => calcRemainder( value.target.value )} 
                                        type='number' 
                                        style={{ width:'95%', fontSize:'25px', fontWeight:'bold', justifyContent:'end', textAlign:'end', display:'flex',justifyContent:'center'}} 
                                        suffix={<div style={{fontSize:'18px', fontWeight:'normal'}}>{ remainder > 0 ? ' Resto: ' + remainder.toFixed(2) + '€'  : ''}</div>}
                                        placeholder='Contanti ricevuti'
                                        >
                                    </Input>
                                </div>
                                <Divider style={{margin:'0px'}}></Divider>
                                <div className='container-payments-type' style={{ ...getColorTheme1() }}>
                                    {
                                        JSON.parse( localStorage.getItem('payments') ).map( p => <div onClick={() => sendingReceipt( p ) } style={{ ...getColorTheme2() }} key={ p.id+p.code } className='btn-payment'>{ p.description }</div>)
                                    }
                                </div>
                                
                            </div>
                        </div>

                        <div className="sub-top-bar" >
                                <div 
                                    className={ 'text-sub-top-bar' } 
                                    style={{ display:'flex', justifyContent:'center', alignItems:'center', width:'50%',...getColorTheme1()}}
                                    >
                                        <div style={{marginRight:'5px', fontSize:'18px' }}> <GiftOutlined  /></div>
                                        <div style={{ fontSize:'18px' }}>Lotteria</div>
                                </div>
                                               {/* MODALE SVUOTA  CARRELLO*/}
                                <div
                                    onClick={()=> setModalCleanCart( true )}
                                    className={ 'text-sub-top-bar' } 
                                    style={{ display:'flex', justifyContent:'center', alignItems:'center', width:'50%',...getColorTheme1()}}
                                    >
                                        <div style={{marginRight:'5px', fontSize:'18px',...getColorTheme1()}}> <DeleteOutlined  /> </div>
                                        <div style={{fontSize:'18px',...getColorTheme1() }}>Svuota</div>
                                </div>
                                <div onClick={( e ) => {
                                    if( e.target.className != 'modal-delete-product' ) return;
                                        setShowModalDeleteProduct( false )
                                }} className={ modalCleanCart ? 'modal-delete-product' : 'modal-delete-product-hide' } style={{ ...getColorTheme1() }}>
                                <div style={{ width:'65%', height:'22%', backgroundColor: Global.colorGrey, boxShadow:'5px 5px 16px 5px rgba(0,0,0,0.22)', borderRadius:'5px', padding:'20px',...getColorTheme1()}}>
                                    <div style={{ marginBottom:'20px',...getColorTheme1()}}>Svuota carrello</div>
                                    <div style={{ }}>Sicuro di voler procedere?</div>
                                    <div style={{ width:'100%', backgroundColor:'', position:'relative', bottom:'-50px', height:'30px', display:'flex', justifyContent:'space-between', gap:'2px'}}>
                                        <div style={{ color: Global.colorBgOne }} onClick={() => { setModalCleanCart( false ) }}>ANNULLA</div>
                                        <div onClick={() => {
                                            Global.cart.products = []
                                            setProductsCartSelected( [] )
                                            calcTotalCart() 
                                            setModalCleanCart( false )
                                            }} style={{ color: Global.colorBgOne }}>PROCEDI</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='cart-amount' style={{ color: Global.colorBgOne }} >
                            <div>
                                { `Totale (${ totalCart.quantity } p.zi)`}
                            </div>
                            <div style={{fontSize:'25px', fontWeight:'bold'}}>
                                { `${ totalCart.amount }€`  }
                            </div>
                        </div>
                        <div 
                            className='button-payment'
                            onClick={() => setShowPayments( true )}
                        >
                            <CreditCardOutlined /> PAGA { productsCartSelected.length >  0 ? `${ totalSelectedCart.amount }€` : '' }
                        </div>
                    </div>

                </div>

{                                                    /* BOTTONI PRODOTTI SERVIZI E TOTALE */                                                                              }  
                <div className='bottom-bar'>

                    <div 
                        className={ pageBottom != 'catalog' ? 'text-bottom-bar' : 'text-bottom-bar-selected'}
                        style={{ ...getColorTheme1()}}
                        onClick={() => setPageBottom('catalog')}
                    >
                        <div style={{ width:'100%', height: pageBottom != 'catalog' ? '0px' : '5px', backgroundColor:Global.colorBgTwo, position:'absolute', top:'0px',}}></div>
                        Prodotti e Servizi
                    </div>

                    <div
                        ref={ refTotal } 
                        className={ pageBottom != 'cart' ? 'text-bottom-bar' : 'text-bottom-bar-selected'}
                        style={{ ...getColorTheme1()}}
                        onClick={() => setPageBottom('cart')}
                        >
                            <div style={{ width:'100%', height: pageBottom != 'cart' ? '0px' : '5px', backgroundColor:Global.colorBgTwo, position:'absolute', top:'0px'}}></div>
                            <div style={{textAlign:'center', fontWeight:'bold'}}>Totale</div>
                            <div style={{textAlign:'center'}}>{`(${totalCart.quantity}) ${totalCart.amount}€`}</div>
                    </div>

                </div>
            </div>
        </>
    )
}