import { LeftOutlined, ArrowsAltOutlined, SearchOutlined  } from '@ant-design/icons'
import { Global } from '../Global'
import { useNavigate } from 'react-router-dom'
import {  Input, } from 'antd'
import { useEffect, useState,  } from 'react'


export function VatsPage() {
    const [vats, setVats] = useState( [] )
    const navigate = useNavigate()

    useEffect(() => {
        setVats( JSON.parse( localStorage.getItem('vats') ) )
    },[ ])

    function filterVats ( value ) {
        setVats( JSON.parse( localStorage.getItem('vats') ).filter( c => c.description.toLowerCase().includes( value.target.value.toLowerCase() )))
    }

 //SETTAGGIO TEMA 1
 function getColorTheme1() {
    if( localStorage.getItem('theme') == 'dark' ){
        return { backgroundColor: Global.darkColor.bgColor1, color: Global.darkColor.textColor1  }
    }else{
        return {   }
    }
    }

    //SETTAGGIO TEMA 2
    function getColorTheme2() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor2, color: Global.darkColor.textColor1  }
        }else{
            return { backgroundColor: Global.lightColor.bgColor1, color: Global.lightColor.textColor1  }
        }
    }

    return(
        <div  className="base-page" style={{...getColorTheme1()}}>
            
            <div className="base-top-bar-page" style={{...getColorTheme2()}}>
                <div onClick={ () => navigate('/menu',{replace:true}) } style={{ display:'flex', justifyContent:'center', alignItems:'center', gap:'5px' }}> 
                    <LeftOutlined style={{ color:Global.colorBgOne, fontSize:'20px',}}/>
                    <div style={{ color:Global.colorBgOne, fontSize:'15px'}}>MENU</div> 
                </div>
                <div style={{ color:Global.colorBgOne, fontSize:'20px', display:'flex', height:'100%', alignItems:'center'}}>Aliquote</div>
                <div onClick={() => navigate(`/vat/edit`)} style={{ color:Global.colorBgOne, fontSize:'15px', display:'flex', height:'100%', alignItems:'center'}}>AGGIUNGI</div>
            </div>
            <div style={{ position: 'absolute', top:'10dvh', width:'100dvw', display:'flex', justifyContent:'center', padding:'0px 15px 0px 15px'}}>
                <Input prefix={<SearchOutlined  style={{ color:Global.colorBgOne, fontSize:'20px',}}/>} placeholder='Cerca' onChange={( value ) => filterVats( value )}></Input>
            </div>
            
            <div className='categories-page-container'>
                {
                  vats.map( c => {
                    
                    return <div key={c.id+c.description} onClick={() => { navigate(`/vat/edit/${ c.id }`) }} className='categories-page-box' draggable={true} >
                        <div style={{ display:'flex', flexDirection:'column', height:'100%', justifyContent:'center', marginLeft:'10px'}}>
                            <div>IVA:</div>
                            <div>Nome:</div>
                        </div>

                        <div style={{ display:'flex', width:'70%', flexDirection:'column', height:'100%', justifyContent:'center', marginLeft:'10px'}}>
                            <div>{c.value+'%'}</div>
                            <div>{c.description}</div>
                        </div>
                        <ArrowsAltOutlined  style={{ fontSize:'25px', rotate:'135deg', color:Global.colorBgOne, marginRight:'20px'}}/>
                    </div>
                  }) 
                }
            </div>   
        </div>
    )
}