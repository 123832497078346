import { LeftOutlined, ArrowsAltOutlined  } from '@ant-design/icons'
import { Global } from '../Global'
import { useNavigate, useParams } from 'react-router-dom'
import { ColorPicker, Form, Select,message } from 'antd'
import { useEffect, useState,  } from 'react'


export function VatForm() {
    const navigate = useNavigate()
    const  [form]  = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage();
    const { id } = useParams()
    const [v, setV ] = useState( null )
    const [showModalDeleteProduct, setShowModalDeleteProduct] = useState( false )

    useEffect(() => {

        if( id ){
            let index = JSON.parse( localStorage.getItem('vats') ).findIndex( v => v.id == id)
            setV( JSON.parse( localStorage.getItem('vats') )[ index ] )


           
        }
   },[])

   useEffect(()=> {
    if( !v ) return
        form.setFieldsValue({
            'code' : v.code,
            'description': v.description,
            'value' : v.value,
            'collectability': v.collectability
        })
   },[ v ])

   async function getVatList () {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("jwt", Global.jwt );

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        try{
            const response = await fetch( `${ Global.END_POINT }/api/mb/v1/vat/list?page=0&record_for_page=10000` , requestOptions)
          if ( response.status == 200 ){
                let json = await response.json();
                if( json.Result == 'OK' ){
                        Global.vatList = json.Json.vats
                        localStorage.setItem( 'vats', JSON.stringify( json.Json.vats ))
                }
                navigate(-1,{replace: true})
            }
        }catch( e ){ console.error('errore getVat')}
    }
    

    async function saveVat ( ) {
        
        if( form.getFieldsValue().code == undefined || form.getFieldsValue().value == undefined || form.getFieldsValue().description == undefined || form.getFieldsValue().collectability == undefined ){
            form.validateFields()
            messageApi.open({
                type: 'error',
                content: 'Errore nel caricamento IVA',
            });
            return
        }

        try{
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("jwt", localStorage.getItem('jwt') );
            let value = form.getFieldsValue()

            var raw = {
                "code": value.code,
                "description": value.description,
                "collectability": value.collectability,
                "value": value.value
            }
            
            if( id ){
                raw.id = id
            }
            
        
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify( raw ) ,
                redirect: 'follow'
            };

            
            const response = await fetch(`${ Global.END_POINT }/api/mb/v1/vat/create`, requestOptions)
            if( response.status == 201){
                let json = await response.json()
                if( json.Result == 'OK'){
                    await getVatList ()
                   
                }else if( json.Description.includes('Duplicate')){
                    messageApi.open({
                        type: 'error',
                        content: 'Codice duplicato',
                    });
                }else{
                    messageApi.open({
                        type: 'error',
                        content: 'Errore nel caricamento IVA',
                    });
                }
            }
            
            
        }catch( e ){
            console.log( e ); 
            messageApi.open({
                type: 'error',
                content: 'Errore nel caricamento IVA',
                });
        }
    }

 
    async function removeVat ( id ){
    
        try{
            var myHeaders = new Headers();
            myHeaders.append("jwt", localStorage.getItem('jwt') );
    
        
            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };
    
            
            const response = await fetch(`${ Global.END_POINT }/api/mb/v1/vat/delete?id_vat=${ id }`, requestOptions)
            if( response.status == 200){
                let json = await response.json()
                if( json.Result == 'OK'){
                    await getVatList ()
                    setShowModalDeleteProduct( false )
                }else{
                    setShowModalDeleteProduct( false )
                    messageApi.open({
                        type: 'error',
                        content: 'Impossibile eliminare aliquota',
                      });
                }
            }
            
            
        }catch( e ){
            console.log( e );
            setShowModalDeleteProduct( false ) 
            messageApi.open({
                type: 'error',
                content: 'Impossibile eliminare la categoria',
                });
        }
    }


     //SETTAGGIO TEMA 1
 function getColorTheme1() {
    if( localStorage.getItem('theme') == 'dark' ){
        return { backgroundColor: Global.darkColor.bgColor1, color: Global.darkColor.textColor1  }
    }else{
        return {   }
    }
    }

    //SETTAGGIO TEMA 2
    function getColorTheme2() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor2, color: Global.darkColor.textColor1  }
        }else{
            return { backgroundColor: Global.lightColor.bgColor1, color: Global.lightColor.textColor1  }
        }
    }

    return(
        <div  className="base-page" style={{...getColorTheme1()}}>
            {contextHolder}
            <div onClick={( e ) => {
                if( e.target.className != 'modal-delete-product' ) return;
                    setShowModalDeleteProduct( false )
            }} style={{ zIndex:'12', }} className={ showModalDeleteProduct ? 'modal-delete-product' : 'modal-delete-product-hide' }>
            <div style={{ width:'65%', height:'22%', backgroundColor: Global.colorGrey, boxShadow:'5px 5px 16px 5px rgba(0,0,0,0.22)', borderRadius:'5px', padding:'20px',...getColorTheme1()}}>
                <div style={{ marginBottom:'20px'}}>Elimina</div>
                <div style={{ }}>Sicuro di voler procedere?</div>
                <div style={{ width:'100%', backgroundColor:'', position:'relative', bottom:'-50px', height:'30px', display:'flex', justifyContent:'space-between', gap:'2px'}}>
                    <div style={{ color: Global.colorBgOne }} onClick={() => { setShowModalDeleteProduct( false )}}>ANNULLA</div>
                    <div onClick={() => {
                            removeVat( v.id )
                        }} style={{ color: Global.colorBgOne }}>PROCEDI</div>
                </div>
            </div>
            </div>
                                            {                 /* PAGINA MODIFICA*/             }
            <div className='page-mod-category' style={{...getColorTheme1()}}>
                <div className='base-top-bar-page' style={{ alignItems:'center',  ...getColorTheme2()}}>
                    <div style={{ color:Global.colorBgOne, fontSize:'20px',  ...getColorTheme2()}}>{ v ? 'Modifica Aliquota' : 'Aggiungi Aliquota'}</div>
                    <div style={{display:'flex', gap: '20px',  ...getColorTheme2()}}>
                        <div onClick={() => {   navigate( -1 )  }} style={{ color: Global.colorBgTwo,  ...getColorTheme2() }}>ANNULLA</div>
                        <div onClick={() => {  saveVat()  }} style={{ color:Global.colorBgTwo, ...getColorTheme2()}}>SALVA</div>
                    </div> 
                </div>
                <div className='page-category-container'>
                    <Form  form={ form }>
                                <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%',  ...getColorTheme1()  }}>Codice:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row'}} name={ 'code' }  rules={[{required: true, message:'Campo obbligatorio'},
                                                    ({ getFieldValue }) => ({
                                                      validator(_, value) {
                                                        if ( !value || JSON.parse( localStorage.getItem('categories') ).findIndex( e => e.code.toLowerCase() == value.toLowerCase() ) == -1 ) {
                                                          return Promise.resolve();
                                                        }
                                                        console.log( JSON.parse( localStorage.getItem('categories') ).findIndex( e => e.code.toString() == value.toString() ) )
                                                        return Promise.reject( new Error('Codice duplicato') );
                                                      },
                                                    }),
                                                  ]}>
                                <input style={{  ...getColorTheme1()  }} maxLength={5} minLength={2} placeholder='Inserisci il codice 5 caratteri' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>
                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()    }}>Nome:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'100%'}} name={ 'description' } rules={[{ required: true, message:'Campo obbligatorio' }]}>
                                    <input style={{  ...getColorTheme1()  }}placeholder='Inserisci descrizione' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>
                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()    }}>Valore:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row'}} name={ 'value' } rules={[{ required: true, message:'Campo obbligatorio' }]}>
                                    <input style={{  ...getColorTheme1()  }}type='number' placeholder='Inserisci il valore aliquota' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>
                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'80%', ...getColorTheme1()    }}>Esigibilità aliquota:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'100%', marginRight:'20px',  ...getColorTheme1()  }} name={ 'collectability' } rules={[{ required: true, message:'Campo obbligatorio' }]}>
                                    <Select options={[
                                            {label: 'Iva ad esigibilià immadiata' , value:'I'},
                                            {label: 'Iva ad esigibilià differita' , value:'D'},
                                            {label: 'Scissione dei pagamenti' , value:'S'}
                                        ]}></Select>
                                </Form.Item >
                            </div>
                    </Form>
                </div>
                
                <div className="page-mod-product-cart-bottom-bar" style={{display: !id ? 'none' : ''}}>
                    <div onClick={() => { setShowModalDeleteProduct( true )  }} className='btn-delete' ><div>ELIMINA</div></div>                                
                </div>
            </div>

        </div>
    )
}