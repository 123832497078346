import {
    FolderOutlined, 
    FileOutlined, 
    BackwardOutlined,
    AreaChartOutlined,
    FileSearchOutlined,
    SwapOutlined,
    AppstoreOutlined,
    DatabaseOutlined,
    UserOutlined,
    PushpinOutlined,
    HourglassOutlined,
    CloudOutlined,
    PrinterOutlined,
    BgColorsOutlined ,
    SendOutlined,
    SlidersOutlined,
    LogoutOutlined,
    MinusSquareOutlined 
     } from '@ant-design/icons'
import { Divider, Switch } from "antd";
import { Global, resetGlobal } from "../Global";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export function Menu () {
    const navigate = useNavigate()
    const [theme, setTheme ] = useState('dark')
    const [isWow, setIsWow] = useState( false )
    
    useEffect(() => {
        setTheme(  localStorage.getItem('theme') )
        if( !localStorage.getItem('jwt') ){
            navigate('/',{replace: true})
        }
        window.location.href.includes( 'wow') ? setIsWow( true ) :  setIsWow( false )
    }, [])

    //SETTAGGIO TEMA 1
    function getColorTheme1() {
        if( isWow && localStorage.getItem('theme') != 'dark' ){
            return { backgroundColor: '#8CB276', color: 'white'  }
        }

        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor1, color: Global.darkColor.textColor1  }
        }else{
            return {   }
        }
    }
    //SETTAGGIO TEMA 2
    function getColorTheme2() {
        if( isWow && localStorage.getItem('theme') != 'dark' ){
            return { backgroundColor: 'white', color: '#0D600C'  }
        }
        
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor2, color: Global.darkColor.textColor1  }
        }else{
            return { backgroundColor: Global.lightColor.bgColor1, color: Global.lightColor.textColor1  }
        }
    }

    return(
        <div style={{ overflow:'hidden',}}>
                    <div className='page-menu' style={{ ...getColorTheme1() }}>
                        <div
                            className='top-bar-menu' 
                            onClick={() => { navigate('/home', {replace:true}) }}
                            style={{ 
                                ...getColorTheme2()
                                }}>
                            CHIUDI
                        </div>
                        <div className="container-menu">

                            <div className='title-menu' style={{ ...getColorTheme1() }}>I tuoi documenti</div>
                            <Divider style={{ margin:'0px', }}></Divider>
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px', }}
                                onClick={() => console.log('ok')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center', ...getColorTheme1()}}>
                                    <FileOutlined  style={{ fontSize:'1.2em'}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Ultimo documento</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                                onClick={() => console.log('ok')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <BackwardOutlined  style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px', ...getColorTheme1() }}>
                                    <div style={{ marginBottom:'7px'}} >Annulla un documento</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'none', padding:'10px 10px 10px 10px'}}
                                onClick={() => console.log('ok')}
                            >
                                <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <SwapOutlined style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                                </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Fai un reso</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'none', padding:'10px 10px 10px 10px'}}
                                onClick={() => console.log('ok')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <FileSearchOutlined  style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Storico documenti</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'none', padding:'10px 10px 10px 10px'}}
                                onClick={() => console.log('ok')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <AreaChartOutlined   style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Statistiche di vendita</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>



                            <div className='title-menu' style={{ ...getColorTheme1()}}>La tua attività</div>
                            <Divider style={{ margin:'0px',}}></Divider>
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <FolderOutlined   style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div onClick={() => navigate('/categories')} style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Categorie</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                                onClick={() => console.log('ok')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <AppstoreOutlined    style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div onClick={() => navigate('/products')} style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Prodotti e Servizi</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                                onClick={() => navigate('/vats')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <DatabaseOutlined   style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Aliquote</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                                onClick={() => navigate('/departments')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <SlidersOutlined   style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Reparti stampa</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                                onClick={() => navigate('/operators')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <UserOutlined   style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Operatori</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                                onClick={() => navigate('/enterprice/edit')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <PushpinOutlined style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Intestazione, Sede</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>


                            <div className='title-menu' style={{ ...getColorTheme1()}} >Impostazioni</div>
                            <Divider style={{ margin:'0px',}}></Divider>
                            <div 
                                style={{ width:'100%', display:'none', padding:'10px 10px 10px 10px'}}
                                onClick={() => console.log('ok')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center',}}>
                                    <HourglassOutlined style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Licenza</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'none', padding:'10px 10px 10px 10px'}}
                                onClick={() => console.log('ok')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <CloudOutlined style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Il tuo account</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <MinusSquareOutlined   style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Gestione tavoli. non abilitata
                                    <Switch disabled={true} defaultChecked={  false   } onChange={()=> { 
                                       

                                     }} style={{ marginLeft:'50px'}}></Switch></div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                                onClick={() => navigate('/fiscalprinter/edit')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <PrinterOutlined style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Configura Stampante fiscale</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <BgColorsOutlined  style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Tema Chiaro/Scuro <Switch defaultChecked={  localStorage.getItem('theme')  == 'dark' ? true : false   } onChange={()=> { 
                                        if( localStorage.getItem('theme') == 'dark' ){
                                            localStorage.setItem('theme', 'light')
                                            setTheme('light')
                                        }else{
                                            localStorage.setItem('theme', 'dark')
                                            setTheme('dark')
                                        }

                                     }} style={{ marginLeft:'50px'}}></Switch></div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            <div 
                                style={{ width:'100%', display:'none', padding:'10px 10px 10px 10px'}}
                                onClick={() => console.log('ok')}
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <SendOutlined style={{ fontSize:'1.2em', ...getColorTheme1()}}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Segnala problema</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>

                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                                onClick={ () =>{
                                    let printer =    localStorage.getItem('fiscalPrinter')
                                    let favorites =  localStorage.getItem('favorites')
                                    let theme =      localStorage.getItem('theme')
                                    localStorage.clear()
                                    localStorage.setItem('theme', theme)
                                    localStorage.setItem('favorites', favorites)
                                    localStorage.setItem('fiscalPrinter', printer)
                                    resetGlobal()
                                    navigate('/', {'replace': true})
                                    }
                                }
                            >
                            <div style={{ width:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <LogoutOutlined  style={{ fontSize:'1.2em', color:'red' }}/>
                            </div>
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Esci</div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                            
                            <div 
                                style={{ width:'100%', display:'flex', padding:'10px 10px 10px 10px'}}
                                onClick={() => console.log('ok')}
                            >
                                <div style={{ width:'100%',paddingTop:'10px' }}>
                                    <div style={{ marginBottom:'7px', ...getColorTheme1()}} >Versione: 1.0.1 </div>
                                    <Divider style={{ margin:'0px'}}></Divider>
                                </div>         
                            </div>
                        </div>
                    </div>
                </div>
    )
}