import { LeftOutlined, ArrowsAltOutlined, SearchOutlined  } from '@ant-design/icons'
import { Global } from '../Global'
import { useNavigate } from 'react-router-dom'
import {  Input, } from 'antd'
import { useEffect, useState,  } from 'react'


export function ProductsPage() {
    const [products, setProducts ] = useState( [] )
    const [categorySelected, setCategorySelected] = useState()
    const navigate = useNavigate()

    useEffect(() => {
       

    },[ ])

    function filterCategories ( value ) {
        setProducts( JSON.parse( localStorage.getItem('categories') ).filter( c => c.description.toLowerCase().includes( value.target.value.toLowerCase() )))
    }

 //LISTA PRODOTTI PER CATEGORIA
 async function getProducts ( category ) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("jwt", localStorage.getItem('jwt') );


    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    try{
        const response = await fetch( `${ Global.END_POINT }/api/mb/v1/product/list?page=0&record_for_page=1000000&id_category=${ category }` , requestOptions)
        if ( response.status == 200 ){
            let json = await response.json();
            if( json.Result == 'OK' ){
                setProducts(json.Json.products)
            }
        }
    }catch( e ){ console.error('errore')}
}
  //LISTA PRODOTTI RICERCA
  async function getProductSearch ( text ) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("jwt", localStorage.getItem('jwt') );


    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    try{
        const response = await fetch( `${ Global.END_POINT }/api/mb/v1/product/list?page=0&record_for_page=1000000&search=${ text }` , requestOptions)
        if ( response.status == 200 ){
            let json = await response.json();
            if( json.Result == 'OK' ){
                setProducts( json.Json.products )
            }
        }
    }catch( e ){ console.error('errore prodcat')}
}

    //SETTAGGIO TEMA 1
    function getColorTheme1() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor1, color: Global.darkColor.textColor1  }
        }else{
            return {   }
        }
    }

    //SETTAGGIO TEMA 2
    function getColorTheme2() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor2, color: Global.darkColor.textColor1  }
        }else{
            return { backgroundColor: Global.lightColor.bgColor1, color: Global.lightColor.textColor1  }
        }
    }

    return(
        <div  className="base-page" style={{ ...getColorTheme1() }}>
            <div className='categories-scroll' style={{  color: Global.colorBgOne, top: '9dvh', ...getColorTheme1()}}>
                            {
                                JSON.parse( localStorage.getItem('categories') ).map( (c) =>  {
                                    return <div key={c.code+c.id} style={ categorySelected == c.id ? { backgroundColor: Global.colorBgOne, color:'white', ...getColorTheme2() } : { textAlign:'center',...getColorTheme1() } } className="btn-category-scroll" onClick={() =>{     
                                        getProducts( c.id ) 
                                        setCategorySelected( c.id )
                                    }} >
                                   <div key={c.code+c.id+'bar'} style={{ width:'100%', height:'5px', position:'absolute', top:'0', left:'0',backgroundColor: c.color,}}></div>
                                    { c.description }
                                </div>}
                                )
                            }         
                        </div>
            <div className="base-top-bar-page" style={{...getColorTheme2()}}>
                <div onClick={ () => navigate('/menu',{replace:true}) } style={{ display:'flex', justifyContent:'center', alignItems:'center', gap:'5px', ...getColorTheme2() }}> 
                    <LeftOutlined style={{ color:Global.colorBgOne, fontSize:'20px',}}/>
                    <div style={{ color:Global.colorBgOne, fontSize:'15px'}}>MENU</div> 
                </div>
                <div style={{ color:Global.colorBgOne, fontSize:'20px', display:'flex', height:'100%', alignItems:'center'}}>Prodotti</div>
                <div onClick={() => navigate(`/products/edit`)} style={{ color:Global.colorBgOne, fontSize:'15px', display:'flex', height:'100%', alignItems:'center'}}>AGGIUNGI</div>
            </div>
            <div style={{ position: 'absolute', top:'18dvh', width:'100dvw', display:'flex', justifyContent:'center', padding:'0px 15px 0px 15px'}}>
                <Input prefix={<SearchOutlined  style={{ color:Global.colorBgOne, fontSize:'20px',}}/>} placeholder='Cerca' onChange={ (value) => {
                                        if( value.target.value.length < 3 ) setProducts([])
                                        if( value.target.value.length < 3 ) return
                                        getProductSearch(value.target.value)
                                        }}></Input>
            </div>
            
            <div className='categories-page-container' style={{ top:'24dvh', height:'76dvh'}}>
                {
                  products.map( c => {
                    
                    return <div key={ c.id+c.description } onClick={() => { navigate(`/products/edit/${c.id}`, {state:{...c}}) }} className='categories-page-box' style={{ justifyContent:'start'}} >
                        <div style={{ display:'flex', flexDirection:'column', height:'100%', justifyContent:'center', marginLeft:'10px', fontSize:'15px', gap:'7px', }}>
                            <div>Nome:</div>
                            <div>Prezzo:</div>
                        </div>

                        <div style={{ display:'flex', width:'70%', flexDirection:'column', height:'100%', justifyContent:'center', marginLeft:'10px', gap:'7px'}}>
                            <div>{ c.description }</div>
                            <div>{ c.prices[0] ? ( c.prices[0].taxable + ( ( c.prices[0].taxable/100) * c.vat.value) ).toFixed(2)+' €' : '0€' }</div>
                        </div>
                    </div>
                  }) 
                }
            </div>   
        </div>
    )
}