import { LeftOutlined, ArrowsAltOutlined  } from '@ant-design/icons'
import { Global } from '../Global'
import { useNavigate, useParams } from 'react-router-dom'
import { ColorPicker, Form, Select,message } from 'antd'
import { useEffect, useState,  } from 'react'


export function CategoryForm() {
    const [departments, setDepartments] = useState([])
    const navigate = useNavigate()
    const  [form]  = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage();
    const { id } = useParams()
    const [c, setC ] = useState( null )
    const [showModalDeleteProduct, setShowModalDeleteProduct] = useState( false )

    useEffect(() => {
    let dd = [] 
       JSON.parse( localStorage.getItem('departments') ).forEach((d) => { delete d.updated_at;  dd.push({ 'label': d.description, 'value': d.id })})
        setDepartments( dd )
        if( id ){
            let c_ = JSON.parse( localStorage.getItem('categories') ).filter(cc => cc.id == id)[0] 
            form.setFieldsValue({
                'description' :  c_.description,
                'code' : c_.code,
                'department': c_.department && c_.department.id ? c_.department.id : null,
                'color' : c_.color
            })
            setC( c_ )
       }
       

   },[])

    async function getCategories () {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("jwt", localStorage.getItem('jwt') );
    
    
    
        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
    
        try{
        const response = await fetch( `${ Global.END_POINT }/api/mb/v1/category/get_all_categories` , requestOptions)
            if ( response.status == 200 ){
                let json = await response.json();
                 if( json.Result == 'OK' ){ 
                    Global.gategoriesList = json.Json.categories
                    localStorage.setItem( 'categories', JSON.stringify( json.Json.categories ))
    
                }
            }
        }catch( e ){ console.error('errore getVat')}
}
    async function saveCategory ( ) {
        
 if( form.getFieldsValue().code == undefined || form.getFieldsValue().department == undefined || form.getFieldsValue().description == undefined ){
    form.validateFields()
    messageApi.open({
        type: 'error',
        content: 'Errore nel caricamento della categoria',
      });
      return
 }

    try{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("jwt", localStorage.getItem('jwt') );
        let value = form.getFieldsValue()

        var raw = {
            "code": value.code,
            "description": value.description,
            "image": "",
            "color": value.color && value.color.metaColor && value.color.metaColor.r ? `rgb(${value.color.metaColor.r},${value.color.metaColor.g},${value.color.metaColor.b})` : value.color == null ? 'grey' : value.color,
            "position": 0,
            "disabled": 0,
            "department": { id: value.department }
        }
        
        if( id ){
            raw.id = id
        }
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify( raw ) ,
            redirect: 'follow'
        };

        const response = await fetch(`${ Global.END_POINT }/api/mb/v1/category/create`, requestOptions)
        if( response.status == 201){
            let json = await response.json()
            if( json.Result == 'OK'){
                await getCategories ()
                navigate(-1)
            }else if( json.Description.includes('Duplicate')){
                messageApi.open({
                    type: 'error',
                    content: 'Codice categoria duplicato',
                  });
            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Errore nel caricamento della categoria',
                  });
            }
        }
        
        
    }catch( e ){
        console.log( e ); 
        messageApi.open({
            type: 'error',
            content: 'Errore nel caricamento della categoria cath',
            });
    }
    }

 
    async function removeCategory ( id ){
        try{
            var myHeaders = new Headers();
            myHeaders.append("jwt", localStorage.getItem('jwt') );
    
        
            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };
    
            
            const response = await fetch(`${ Global.END_POINT }/api/mb/v1/category/delete?id_category=${ id }`, requestOptions)
            if( response.status == 200){
                let json = await response.json()
                if( json.Result == 'OK'){
                    await getCategories ()
                    setShowModalDeleteProduct( false )
                    navigate(-1,{replace: true})
                }else{
                    setShowModalDeleteProduct( false )
                    messageApi.open({
                        type: 'error',
                        content: 'Impossibile eliminare la categoria',
                      });
                }
            }
            
            
        }catch( e ){
            console.log( e );
            setShowModalDeleteProduct( false ) 
            messageApi.open({
                type: 'error',
                content: 'Impossibile eliminare la categoria',
                });
        }
    }


    //SETTAGGIO TEMA 1
    function getColorTheme1() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor1, color: Global.darkColor.textColor1  }
        }else{
            return {   }
        }
    }

    //SETTAGGIO TEMA 2
    function getColorTheme2() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor2, color: Global.darkColor.textColor1  }
        }else{
            return { backgroundColor: Global.lightColor.bgColor1, color: Global.lightColor.textColor1  }
        }
    }

    return(
        <div  className="base-page" style={{ ...getColorTheme1() }}>
            {contextHolder}
            <div onClick={( e ) => {
                if( e.target.className != 'modal-delete-product' ) return;
                    setShowModalDeleteProduct( false )
            }} style={{ zIndex:'12'}} className={ showModalDeleteProduct ? 'modal-delete-product' : 'modal-delete-product-hide' }>
            <div style={{ width:'65%', height:'22%', backgroundColor: Global.colorGrey, boxShadow:'5px 5px 16px 5px rgba(0,0,0,0.22)', borderRadius:'5px', padding:'20px',...getColorTheme1()}}>
                <div style={{ marginBottom:'20px'}}>Elimina</div>
                <div style={{ }}>Sicuro di voler procedere?</div>
                <div style={{ width:'100%', backgroundColor:'', position:'relative', bottom:'-50px', height:'30px', display:'flex', justifyContent:'space-between', gap:'2px',...getColorTheme1()}}>
                    <div style={{ color: Global.colorBgOne }} onClick={() => { setShowModalDeleteProduct( false )}}>ANNULLA</div>
                    <div onClick={() => {
                            removeCategory( c.id )
                        }} style={{ color: Global.colorBgOne }}>PROCEDI</div>
                </div>
            </div>
            </div>
                                            {                 /* PAGINA MODIFICA*/             }
            <div className='page-mod-category' >
                <div className='base-top-bar-page' style={{alignItems:'center',...getColorTheme2()}}>
                    <div style={{ color:Global.colorBgOne, fontSize:'20px'}}>{ c ? 'Modifica categoria' : 'Aggiungi categoria' }</div>
                    <div style={{display:'flex', gap: '20px'}}>
                        <div onClick={() => {   navigate( -1 )  }} style={{ color: Global.colorBgTwo, ...getColorTheme1() }}>ANNULLA</div>
                        <div onClick={() => {  saveCategory()  }} style={{ color:Global.colorBgTwo, ...getColorTheme1()}}>SALVA</div>
                    </div> 
                </div>
                <div className='page-category-container' style={{ ...getColorTheme1()}}>
                    <Form  form={ form }>
                                <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Codice:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row'}} name={ 'code' }  rules={[{required: true, message:'Campo obbligatorio'},
                                                    ({ getFieldValue }) => ({
                                                      validator(_, value) {
                                                        if ( !value || JSON.parse( localStorage.getItem('categories') ).findIndex( e => e.code.toLowerCase() == value.toLowerCase() ) == -1 ) {
                                                          return Promise.resolve();
                                                        }
                                                        console.log( JSON.parse( localStorage.getItem('categories') ).findIndex( e => e.code.toString() == value.toString() ) )
                                                        return Promise.reject( new Error('Codice duplicato') );
                                                      },
                                                    }),
                                                  ]}>
                                <input style={{...getColorTheme1()}} maxLength={5} minLength={2} placeholder='Inserisci il codice 5 caratteri' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>
                        
                        
                                <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%',...getColorTheme1()  }}>Nome:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row'}} name={ 'description' } rules={[{ required: true, message:'Campo obbligatorio' }]}>
                                    <input style={{...getColorTheme1()}} placeholder='Inserisci il nome della categoria' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>
                        
                        
                                <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Reparto di produzione:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'60%'}} name={ 'department' } rules={[{ required: true, message:'Campo obbligatorio' }]}>
                                    <Select placeholder={'Seleziona il reparto'} options={ departments }></Select>
                                </Form.Item >
                            </div>
                        
                                <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%',...getColorTheme1()  }}>Colore:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', }} name={ 'color' }>
                                    <ColorPicker  format={'rgb'} ></ColorPicker>
                                </Form.Item >
                            </div>
                        
                    </Form>
                </div>
                
                <div className="page-mod-product-cart-bottom-bar" style={{display: !id ? 'none' : ''}}>
                    <div onClick={() => { setShowModalDeleteProduct( true )  }} className='btn-delete' ><div>ELIMINA</div></div>                                
                </div>
            </div>

        </div>
    )
}