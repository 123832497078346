import { Button, Form, Input, message, Spin } from "antd";
import { Global, resetGlobal } from "../Global";
import { useNavigate } from "react-router-dom";
import { useEffect, useState,   } from "react";

export function Intro () {
const [ form ] = Form.useForm()
const [messageApi, contextHolder] = message.useMessage()
const navigate = useNavigate()
const [loader, setLoader] = useState( false )
const [isWow, setIsWow] = useState( false )

useEffect(()=>{
    window.location.href.includes( 'wow') ? setIsWow( true ) :  setIsWow( false )
}, [])


//LISTA CATEGORIE
async function getCategories () {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("jwt", Global.jwt );
    
    
    
        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
    
        try{
        const response = await fetch( `${ Global.END_POINT }/api/mb/v1/category/get_all_categories` , requestOptions)
            if ( response.status == 200 ){
                let json = await response.json();
                if( json.Result == 'OK' ){ 
                    Global.gategoriesList = json.Json.categories
                    localStorage.setItem( 'categories', JSON.stringify( json.Json.categories ))
    
                }
            }
        }catch( e ){ console.error('errore getVat')}
}

//LISTA PRODOTTI
async function getProducts () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("jwt", Global.jwt );

    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    try{
        const response = await fetch( `${ Global.END_POINT }/api/mb/v1/product/list?page=0&record_for_page=250` , requestOptions)
        if ( response.status == 200 ){
            let json = await response.json();
            if( json.Result == 'OK' ){
                Global.products = json.Json.products
                localStorage.setItem( 'products', JSON.stringify( json.Json.products ))
            }
        }
    }catch( e ){ console.error('errore prod')}
}

//LISTA ALIQUOTE
async function getVatList () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("jwt", Global.jwt );



    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    try{
        const response = await fetch( `${ Global.END_POINT }/api/mb/v1/vat/list?page=0&record_for_page=10000` , requestOptions)
        if ( response.status == 200 ){
            let json = await response.json();
            if( json.Result == 'OK' ){
                Global.vatList = json.Json.vats
                localStorage.setItem( 'vats', JSON.stringify( json.Json.vats ))
            }
        }
    }catch( e ){ console.error('errore getVat')}
}

//LISTA REPARTI
async function getDepartment() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("jwt", Global.jwt );



    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    try{
        const response = await fetch( `${ Global.END_POINT }/api/mb/v1/production_department/list?page=0&record_for_page=1000000` , requestOptions)
        if ( response.status == 200 ){
            let json = await response.json();
            if( json.Result == 'OK' ){
                Global.departments = json.Json.production_departments
                localStorage.setItem( 'departments', JSON.stringify( json.Json.production_departments ))

            }
        }
    }catch( e ){ console.error('errore getdep')}
}


//LISTA PAGAMENTI
async function getPaymentsList () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("jwt", Global.jwt );



    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    try{
        const response = await fetch( `${ Global.END_POINT }/api/mb/v1/payment/list?page=0&record_for_page=1000` , requestOptions)
        if ( response.status == 200 ){
            let json = await response.json();
            if( json.Result == 'OK' ){
                Global.paymentsList = json.Json.payments
                localStorage.setItem( 'payments', JSON.stringify( json.Json.payments )) 
            }
        }
    }catch( e ){ console.error('errore getVat')}
}

//LISTA OPERATORI
async function getOperators () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("jwt", Global.jwt );



    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    try{
        const response = await fetch( `${ Global.END_POINT }/api/mb/v1/operator/list?page=0&record_for_page=1000` , requestOptions)
        if ( response.status == 200 ){
            let json = await response.json();
            if( json.Result == 'OK' ){
                Global.operators = json.Json.operators
                localStorage.setItem( 'operators', JSON.stringify( json.Json.operators )) 
            }
        }
    }catch( e ){ console.error('errore lista operatori')}
}

//LISTA TAVOLI
async function getTables () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("jwt", Global.jwt );
    
    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    try{
        const response = await fetch( `${ Global.END_POINT }/api/mb/v1/rest_table/list?page=0&record_for_page=1000` , requestOptions)
        if ( response.status == 200 ){
            let json = await response.json();
            if( json.Result == 'OK' ){
                Global.tables = json.Json.rest_tables
                localStorage.setItem( 'tables', JSON.stringify( json.Json.rest_tables )) 
            }
        }
    }catch( e ){ console.error('errore lista tavoli')}
}


//AUTENTICAZIONE
async function login( value ){
    setLoader( true )
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "email": value.email,
        "password": value.password
    });

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };

    try{
        const response = await fetch( `${ Global.END_POINT }/api/mb/v1/jwt/bearer_auth` , requestOptions)
        if( response.status == 201 ){ 
            let json = await response.json()
            if( json.Description == 'Login ok!' ){
                Global.superAdmin = { 'user': 'admin', 'password': value.password }
                Global.enterprise = json.Json.enterprise
                localStorage.setItem('jwt', json.Json.token)
                Global.jwt = json.Json.token;
                Global.user = json.Json;
                localStorage.setItem( 'user', JSON.stringify( json.Json ) )
                localStorage.setItem( 'activeTables', false)
                if( !localStorage.getItem('favorites') ) localStorage.setItem('favorites', '[]')
                if( !localStorage.getItem('fiscalPrinter') ) localStorage.setItem('fiscalPrinter', '{"ip": "", "port": "", serial:"" }')
                if( !localStorage.getItem('theme') ) localStorage.setItem('theme', 'light')
                await getVatList ()
                await getPaymentsList ()
                await getCategories ()
                await getDepartment ()
                await getProducts ()
                await getOperators ()
                await getTables () 
                setLoader( false )
                navigate('/home', { replace:true})
            }else{
                setLoader( false )
                messageApi.open({
                    type: 'error',
                    content: 'Accesso negato',
                });
                console.error('ERRORE LOGIN')
            }
            
        }else{
            setLoader( false )
            messageApi.open({
                type: 'error',
                content: 'Accesso negato',
            });
              
            console.error('ERRORE LOGIN')
        }


    }catch( e ){
        setLoader( false )
        messageApi.open({
            type: 'error',
            content: 'Accesso negato',
        });
        console.log( e ) 
    }
}





    return(
    <>
    { contextHolder }
        <div className="loader" style={{ display: loader ? 'flex' : 'none'}}>
            <Spin size="large"/>
        </div>
        <div className={isWow ? "bg-gradient-wow" : "bg-gradient"} style={{ display:'flex',alignItems:'center', justifyContent:'center', flexDirection:'column' , }}>
            
            <div className={ isWow ? "logo-intro-wow" : "logo-intro"} >
            </div>
            <Form form={ form } style={{width:'80%'}} onFinish={( value ) => { login( value )}}>
                <Form.Item name={'email'} rules={[{ required: true, message:'Inserire email valida', type:'email'}]} >
                    <Input className="input" placeholder="Email" ></Input>
                </Form.Item>
                <Form.Item name={'password'} rules={[{ required: true, message:'Inserire password' }]} >
                    <Input className="input" placeholder="Password" type="password"></Input>
                </Form.Item>
                <Button htmlType="submit" className="btn" style={{ backgroundColor: isWow ? '#4D8940': Global.colorButton}}> Accedi </Button>
            </Form>
        </div>
    </>
        )

}