import { DownOutlined  } from '@ant-design/icons'
import { Global } from '../Global'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Checkbox,Form, Select,Switch,message } from 'antd'
import { useEffect, useState,  } from 'react'


export function ProductForm( ) {
    const [category, setCategory] = useState( [] )
    const [ catSelected, setCatSelected ] = useState( [] )
    const [ showCategories, setShowCategories ] = useState( false )
    const [vat, setVat] = useState([])
    const navigate = useNavigate()
    const  [form]  = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage();
    const { id } = useParams()
    const [p, setP ] = useState( null )
    const [isFavorite, setIsFavorites ] = useState( false )
    const [cat_, setCat_ ] = useState( [] )
    const [showModalDeleteProduct, setShowModalDeleteProduct] = useState( false )

    let location = useLocation()

    useEffect(() => {
        let dd = [] 
        JSON.parse( localStorage.getItem('vats') ).forEach((c) => { dd.push( { label: c.description, value: c.id}  )})
        setVat( dd )

        if( !location.state  ) return
        let cat___ = []
        let cat__ = []
        location.state.products_categories_hierarchy.map( c => { 
            cat___.push( c.category.id ); 
            cat__.push( c.category )
        })
        setCat_( cat___ )
        console.log(cat__);
        setCatSelected( [...cat__ ])

        dd = [] 
        setCategory( dd )
        
        if( location.state ){
            form.setFieldsValue({
                'code' :location.state.code,
                'description' : location.state.description,
                'amount' : ( location.state.prices[0].taxable + ( (location.state.prices[0].taxable/100) * location.state.vat.value) ).toFixed(2),
                'vat' : location.state.vat.id
            })
       }

       if( JSON.parse( localStorage.getItem('favorites') ).includes( location.state ? location.state.id : '' ) ){
            setIsFavorites( true )
        } 
       setP( location.state )

   },[])


   function addCategories( target, c, catSelected) {
        if( !target.target.checked ){
            console.log( catSelected )
            var temp = [...catSelected]
            var index = temp.findIndex( cc => cc.id == c.id )
            temp.splice( index, 1 )
            setCatSelected( temp )
    }else{
        var temp2 = [...catSelected]
        temp2.push( c )
        setCatSelected( temp2 )
    }
   }

    
    async function saveProduct ( ) {
      
        if( form.getFieldsValue().code == undefined || form.getFieldsValue().description == undefined ){
            form.validateFields()
            messageApi.open({
                type: 'error',
                content: 'Errore nel caricamento della categoria',
            });
            return
        }

            try{
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("jwt", localStorage.getItem('jwt') );

                let values = form.getFieldsValue()
                let vat = JSON.parse( localStorage.getItem('vats') )[ JSON.parse( localStorage.getItem('vats') ).findIndex( v => v.id == values.vat )]
                let categories = []

                catSelected.map( c => categories.push( { "category":{ "id": c.id } } ))


                var raw = {
                    "code": values.code,
                    "description": values.description,
                    "product_type": "simple",
                    "combination": null,
                    "barcode": null,
                    "prices": [
                        {
                            "id": null,
                            "taxable": parseFloat( values.amount ) / parseFloat( ('1.'+( vat.value.toString().padStart(2,'0') )) ),
                            "discount": null,
                            "with_tax_included": 1,
                            "price_list" : { "id" : 255 }
                        },
                    ],
                    "um": {
                        "id": 257,
                        "code": "EAUM",
                        "description": "PEZZI - EASYAPPEAR",
                        "easyappear_um": 1
                    },
                    "ums": [],
                    "vat": vat  ,
                    "vat_reverse": null,
                    "production_department": null,
                    "variants": [],
                    "related_products": [],
                    "product_category": {},
                    "products_categories_hierarchy": categories,
                    "product_for_composition": 0,
                    "short_description": null,
                    "long_description": null,
                    "weight": 0,
                    "depth": 0,
                    "height": 0,
                    "width": 0,
                    "ultimate_cost": 0,
                    "role_type": "product",
                    "position": -1,
                    "disabled": 0,
                }
                
                if( location.state && location.state.id ){
                    raw.id = location.state.id
                }
            
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify( raw ) ,
                    redirect: 'follow'
                };
                
                const response = await fetch(`${ Global.END_POINT }/api/mb/v1/product/create`, requestOptions)
                if( response.status == 201){
                    let json = await response.json()
                    if( json.Result == 'OK'){
                        if( !localStorage.getItem('favorites') ) localStorage.setItem( 'favorites', JSON.stringify([]) );
                        if( json.Json.id && isFavorite && !location.state.id ){
                            localStorage.setItem('favorites', JSON.stringify( [ json.Json.id, ...JSON.parse( localStorage.getItem('favorites') )  ] ) )
                        }
                        navigate( -1 )
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Errore nel caricamento del prodotto',
                        });
                    }
                }
                
                
            }catch( e ){
                messageApi.open({
                    type: 'error',
                    content: 'Errore catch',
                    });
            }
    }

 
    async function removeProduct ( id ){
        try{
            var myHeaders = new Headers();
            myHeaders.append("jwt", localStorage.getItem('jwt') );
    
        
            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };
    
            
            const response = await fetch(`${ Global.END_POINT }/api/mb/v1/product/delete?id_product=${ id }`, requestOptions)
            if( response.status == 200){
                let json = await response.json()
                if( json.Result == 'OK'){
                      localStorage.setItem('favorites', JSON.stringify( JSON.parse( localStorage.getItem('favorites') ).filter( f => f != id ) ) ) 
                   navigate( -1 )

                }else{
                    setShowModalDeleteProduct( false )
                    messageApi.open({
                        type: 'error',
                        content: 'Impossibile eliminare il prodotto',
                      });
                }
            }
            
            
        }catch( e ){
            console.log( e );
            setShowModalDeleteProduct( false ) 
            messageApi.open({
                type: 'error',
                content: 'Impossibile eliminare la categoria',
                });
        }
    }


    //SETTAGGIO TEMA 1
    function getColorTheme1() {
    if( localStorage.getItem('theme') == 'dark' ){
        return { backgroundColor: Global.darkColor.bgColor1, color: Global.darkColor.textColor1  }
    }else{
        return {   }
    }
    }

    //SETTAGGIO TEMA 2
    function getColorTheme2() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor2, color: Global.darkColor.textColor1  }
        }else{
            return { backgroundColor: Global.lightColor.bgColor1, color: Global.lightColor.textColor1  }
        }
    }

    return(
        <div  className="base-page">
            {contextHolder}
                               {                       /* Modale eliminazione*/             }
            <div onClick={( e ) => {
                    if( e.target.className != 'modal-delete-product' ) return;
                        setShowModalDeleteProduct( false )
                }} style={{ zIndex:'12'}} className={ showModalDeleteProduct ? 'modal-delete-product' : 'modal-delete-product-hide' }>
                <div style={{ width:'65%', height:'22%', backgroundColor: Global.colorGrey, boxShadow:'5px 5px 16px 5px rgba(0,0,0,0.22)', borderRadius:'5px', padding:'20px',}}>
                    <div style={{ marginBottom:'20px'}}>Elimina</div>
                    <div style={{ }}>Sicuro di voler procedere?</div>
                    <div style={{ width:'100%', backgroundColor:'', position:'relative', bottom:'-50px', height:'30px', display:'flex', justifyContent:'space-between', gap:'2px'}}>
                        <div style={{ color: Global.colorBgOne }} onClick={() => { setShowModalDeleteProduct( false )}}>ANNULLA</div>
                        <div onClick={() => {
                                removeProduct( p.id )
                            }} style={{ color: Global.colorBgOne }}>PROCEDI</div>
                </div>
            </div>
            </div>
                                      {                 /* Modale categorie*/             }
            <div onClick={( e ) => {
                    
                }} style={{ zIndex:'12'}} className={ showCategories ? 'modal-delete-product' : 'modal-delete-product-hide' }>
                <div style={{ width:'65%', height:'80%', backgroundColor: Global.colorGrey, boxShadow:'5px 5px 16px 5px rgba(0,0,0,0.22)', borderRadius:'5px', padding:'20px',...getColorTheme1()}}>
                    <div style={{ marginBottom:'20px', ...getColorTheme1()}}>Categorie</div>
                    <div style={{ width:'100%', height:'80%', overflowY:'auto' }}>
                            { JSON.parse( localStorage.getItem('categories') ).map(( c ) => { 
                                return <div style={{ width:'50%', ...getColorTheme1()}}> <Checkbox defaultChecked={ cat_.includes( c.id ) } onChange={ ( target ) => { 
                                addCategories( target, c, catSelected )
                        }}><div style={{...getColorTheme1()}}>{ c.description }</div></Checkbox> </div> }) }
                    </div>
                    <div style={{ width:'100%', backgroundColor:'', position:'relative', bottom:'-50px', height:'30px', display:'flex', justifyContent:'space-between', gap:'2px'}}>
                        <div onClick={() => {
                                setShowCategories( false )
                            }} style={{ color: Global.colorBgOne }}>PROCEDI</div>
                </div>
            </div>
            </div>
                                            {                 /* PAGINA MODIFICA*/             }
            <div className='page-mod-category' >
                <div className='base-top-bar-page' style={{alignItems:'center', ...getColorTheme2()}}>
                    <div  onClick={()=> console.log( catSelected )} style={{ color:Global.colorBgOne, fontSize:'20px'}}>{ p ? 'Modifica Prodotto' : 'Aggiungi Prodotto' }</div>
                    <div style={{display:'flex', gap: '20px'}}>
                        <div onClick={() => {   navigate( -1 )  }} style={{ color: Global.colorBgTwo, ...getColorTheme2() }}>ANNULLA</div>
                        <div onClick={() => {  saveProduct()  }} style={{ color:Global.colorBgTwo, ...getColorTheme2()}}>SALVA</div>
                    </div> 
                </div>
                <div className='page-category-container' style={{ ...getColorTheme1() }}>
              
                    <Form  form={ form }>
                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start', ...getColorTheme1()}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%'  }}>Codice:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row',width: '100%'}} name={ 'code' }  rules={[{required: true, message:'Campo obbligatorio'}]}>
                                    <input style={{ ...getColorTheme1()}} maxLength={5} minLength={2} placeholder='Inserisci il codice 5 caratteri' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>
                        
                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start',...getColorTheme1()}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%'  }}>Nome:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row',width: '100%'}} name={ 'description' } rules={[{ required: true, message:'Campo obbligatorio' }]}>
                                    <input style={{ ...getColorTheme1() }} placeholder='Inserisci il nome della categoria' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>
                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Prezzo - ivato:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width: '100%'}} name={ 'amount' } rules={[{ required: true, message:'Campo obbligatorio' }]}>
                                    <input style={{ ...getColorTheme1() }} placeholder='Inserisci il nome della categoria' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>
                        
                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start', ...getColorTheme1()}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%'  }}>Categorie:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'60%'}} name={ 'categories' } >
                                    <div onClick={()=> setShowCategories( true )} style={{ width:'100%', border:'2px solid #CACACA', display:'flex', justifyContent:'end', padding:'5px', borderRadius:'5px', color:'#CACACA'}} >
                                        <div style={{ width:'80%', display:'flex'}}>
                                            {
                                                (catSelected).map( c => c.description+', ')
                                            }
                                        </div>
                                    <div style={{ width:'100%'}}>{}</div> <DownOutlined color='#F5F5F5'/></div>
                                </Form.Item >
                               
                            </div>
                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Aliquota:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'60%', marginTop:'10px', ...getColorTheme1()}} name={ 'vat' } rules={[{ required: true, message:'Campo obbligatorio' }]}>
                                    <Select placeholder={'Seleziona aliquota'} options={ vat }></Select>
                                </Form.Item >
                            </div>
                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%',...getColorTheme1()  }}>Preferiti:</div>
                                <Form.Item name={'favorite'} style={{ width: '100%',...getColorTheme1()}}>
                                    <Switch checked={ isFavorite } style={{ position:'absolute'}} onChange={( target )=> {
                                        if( !p ){ 
                                            setIsFavorites( !isFavorite )
                                            return
                                        }
                                        setIsFavorites( !isFavorite )
                                        if( target ) {
                                            if( !localStorage.getItem('favorites') ) localStorage.setItem( 'favorites', JSON.stringify([]) )
                                            localStorage.setItem('favorites', JSON.stringify( [ p.id, ...JSON.parse( localStorage.getItem('favorites') )  ] ) )
                                        }else{
                                            let temp = JSON.parse( localStorage.getItem('favorites') )
                                            let index = temp.findIndex( f => f == p.id )
                                            if( index == - 1 ) return
                                            temp.splice( index, 1)
                                            localStorage.setItem('favorites', JSON.stringify( temp ))
                                        }
                                    }}></Switch>
                                </Form.Item>
                            </div>
                    </Form>
                </div>
                
                <div className="page-mod-product-cart-bottom-bar" style={{display: !id ? 'none' : ''}}>
                    <div onClick={() => { setShowModalDeleteProduct( true )  }} className='btn-delete' ><div>ELIMINA</div></div>                                
                </div>
            </div>

        </div>
    )
}