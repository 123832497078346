import { Global } from '../Global'
import { useNavigate } from 'react-router-dom'
import { ColorPicker, Form, Select, message } from 'antd'
import { useEffect, useState,  } from 'react'


export function FiscalPrinterForm() {
    const navigate = useNavigate()
    const  [form]  = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage();



   useEffect(()=> {

    let printer = JSON.parse( localStorage.getItem('fiscalPrinter') )
        form.setFieldsValue({
            'port': printer.port,
            'ip':   printer.ip,
            'serial' : printer.serial
        })
   },[])

   function saveFiscalPrinter() {
    console.log( form.getFieldsValue() )

    localStorage.setItem('fiscalPrinter', JSON.stringify( {ip: form.getFieldValue('ip') , port: form.getFieldValue('port') ,  serial:form.getFieldValue('serial')}))
   }

    //SETTAGGIO TEMA 1
    function getColorTheme1() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor1, color: Global.darkColor.textColor1  }
        }else{
            return {   }
        }
    }
    //SETTAGGIO TEMA 2
    function getColorTheme2() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor2, color: Global.darkColor.textColor1  }
        }else{
            return { backgroundColor: Global.lightColor.bgColor1, color: Global.lightColor.textColor1  }
        }
    }
    
    
    return(
        <div  className="base-page" style={{ ...getColorTheme1() }}>
            {contextHolder}
          
                                            {                 /* PAGINA MODIFICA*/             }
            <div className='page-mod-category' >
                <div className='base-top-bar-page' style={{alignItems:'center', ...getColorTheme2()}}>
                    <div onClick={ ()=> console.log( form.getFieldsValue())} style={{ color:Global.colorBgOne, fontSize:'20px'}}>{'Stampante Fiscale'}</div>
                    <div style={{display:'flex', gap: '20px'}}>
                        <div onClick={() => {   navigate( -1 )  }} style={{ color: Global.colorBgTwo,...getColorTheme2() }}>ANNULLA</div>
                        <div onClick={() => {  saveFiscalPrinter()  }} style={{ color:Global.colorBgTwo, ...getColorTheme2()}}>SALVA</div>
                    </div> 
                </div>
                <div className='page-category-container' style={{...getColorTheme1()}}>
                    <Form style={{ marginTop:'20px'}} form={ form }>
                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>IP stampante:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row',width:'100%' }} name={'ip'} rules={[{ required: true, message:'Campo obbligatorio' }]}>
                                    <input   style={{ width:'100%', ...getColorTheme1()}} type='text' placeholder='Inserisci l`ip della stampante'  className='input-mod'></input>
                                </Form.Item >
                            </div>

                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Porta:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row',width:'100%'}} name={ 'port' } rules={[{ required: true, message:'Campo obbligatorio' }]}>
                                    <input style={{ ...getColorTheme1() }}  type='number' placeholder='Inserisci la porta della stampante' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>

                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Seriale:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row',width:'100%'}} name={ 'serial' } rules={[{ required: true, message:'Campo obbligatorio' }]}>
                                    <input style={{ ...getColorTheme1() }} type='number' placeholder='Inserisci il seriale' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>
                            
                    </Form>
                </div>
             
            </div>

        </div>
    )
}