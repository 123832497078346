import { LeftOutlined, ArrowsAltOutlined  } from '@ant-design/icons'
import { Global } from '../Global'
import { useNavigate, useParams } from 'react-router-dom'
import {  Form, Select,message,Spin  } from 'antd'
import { useEffect, useState,  } from 'react'

export function EnterpriseForm() {
    const navigate = useNavigate()
    const [ form ]  = Form.useForm()
    const [ messageApi, contextHolder ] = message.useMessage();
    const [ regime , setRegime ] = useState( [] )
    const [ cities , setCities ] = useState( [] )

    
    useEffect(() => {
        let rgs = []
        Global.taxRegime.map( r => rgs.push( {label: r.value, value: r.key}))
        setRegime( rgs )
        let cts = []
        Global.itCity.map( c => {
            c.province.map( cc => {
                cc.comuni.map(ccc=> {
                    ccc.provincia = cc.code
                    cts.push({ value: JSON.stringify( ccc ) , label: ccc.nome})
                })
                
            })
        })

       setCities(cts)
      
       console.log( Global.enterprise )
       form.setFieldsValue({
            "business_name": Global.enterprise.business_name,
            "name": Global.enterprise.name,
            "surname": Global.enterprise.surnam,
            "tax_regime": Global.enterprise.tax_regime,
            "fiscal_code": Global.enterprise.fiscal_code,
            "vat_number": Global.enterprise.vat_number,
            "office_address": Global.enterprise.office_address,
            "office_house_number": Global.enterprise.office_house_number,
            "office_zip_code": Global.enterprise.office_zip_code,
            "office_collective": Global.enterprise.office_collective,
            "office_province": Global.enterprise.office_province,
       })
   },[])


    const onChange = (value) => {
        form.setFieldsValue({
            'office_zip_code' : JSON.parse( value ).cap,
            'office_province' : JSON.parse( value ).provincia,
        })
    };

    async function saveEnterprise( ) {
        
        if( form.getFieldsValue().business_name == undefined || form.getFieldsValue().tax_regime == undefined || form.getFieldsValue().vat_number == undefined ){
            form.validateFields()
            messageApi.open({
                type: 'error',
                content: 'Errore',
            });
            return
        }

        try{
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("jwt", localStorage.getItem('jwt') );
            let value = form.getFieldsValue()

            var raw = {
                "id":                           Global.enterprise.id,
                "business_name":                value.business_name,
                "tax_regime":                   value.tax_regime,
                "fiscal_code":                  value.fiscal_code,
                "vat_number":                   value.vat_number,
                "office_address":               value.office_address,
                "office_house_number":          value.office_house_number,
                "office_zip_code":              value.office_zip_code,
                "office_collective":            JSON.parse( value.office_collective ).nome,
                "office_province":              value.office_province,
                "office_nation":                value.office_nation,
            }            
        
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify( raw ) ,
                redirect: 'follow'
            };

            const response = await fetch(`${ Global.END_POINT }/api/mb/v1/enterprise/create`, requestOptions)
            if( response.status == 201){
                let json = await response.json()
                if( json.Result == 'OK'){
                    Global.enterprise = json.Json
                    navigate(-1, {replace:true})
                }else{
                    messageApi.open({
                        type: 'error',
                        content: 'Errore',
                    });
                }
            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Errore',
                });
            }
            
        }catch( e ){
            console.log( e ); 
            messageApi.open({
                type: 'error',
                content: 'Errore',
                });
        }
    }


    const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    //SETTAGGIO TEMA 1
    function getColorTheme1() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor1, color: Global.darkColor.textColor1  }
        }else{
            return {   }
        }
    }
    //SETTAGGIO TEMA 2
    function getColorTheme2() {
        if( localStorage.getItem('theme') == 'dark' ){
            return { backgroundColor: Global.darkColor.bgColor2, color: Global.darkColor.textColor1  }
        }else{
            return { backgroundColor: Global.lightColor.bgColor1, color: Global.lightColor.textColor1  }
        }
    }
    

    return(
        <div  className="base-page" style={{ ...getColorTheme1() }}>
            {contextHolder}
                                            {                 /* PAGINA MODIFICA*/             }
            <div className='page-mod-category' style={{ ...getColorTheme1() }}>
                <div className='base-top-bar-page' style={{alignItems:'center', ...getColorTheme2()}}>
                    <div onClick={ ()=> {    }} style={{ color:Global.colorBgOne, fontSize:'20px'}}>{ 'Dati Azienda' }</div>
                    <div style={{display:'flex', gap: '20px'}}>
                        <div onClick={() => {   navigate( -1 )  }} style={{ color: Global.colorBgTwo, ...getColorTheme2() }}>ANNULLA</div>
                        <div onClick={() => {  saveEnterprise()  }} style={{ color:Global.colorBgTwo, ...getColorTheme2()}}>SALVA</div>
                    </div> 
                </div>
                <div className='page-category-container'>
                    <Form  form={ form }>

                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Ragione sociale:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'100%'}} name={ 'business_name' }  rules={[{required: true, message:'Campo obbligatorio'}]}>
                                    <input  style={{ ...getColorTheme1() }} placeholder='Ragione sociale' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>
                           
                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Regime fiscale:</div>
                                <Form.Item style={{ marginRight: '15px', marginBottom:'0', flexDirection:'row', width:'80%', justifyContent:'start'}} name={ 'tax_regime' }  rules={[{required: true, message:'Campo obbligatorio'}]}>
                                    <Select
                                        placeholder={'Seleziona il regime fiscale'}
                                        options={ regime }
                                     ></Select>
                                </Form.Item > 
                            </div>

                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Codice fiscale:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'100%'}} name={ 'fiscal_code' }  rules={[{required: true, message:'Campo obbligatorio'}]}>
                                    <input  style={{ ...getColorTheme1() }} placeholder='Inserisci il codice fiscale' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>

                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Partita IVA:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'100%'}} name={ 'vat_number' }  rules={[{required: true, message:'Campo obbligatorio'}]}>
                                    <input  style={{ ...getColorTheme1() }} placeholder='Inserisci la partita iva' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>

                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Comune:</div>
                                <Form.Item style={{ marginRight: '15px', marginBottom:'0', flexDirection:'row', width:'75%'}} name={ 'office_collective' }  rules={[{required: true, message:'Campo obbligatorio'}]}>
                                <Select
                                    showSearch
                                    placeholder="Seleziona comune"
                                    optionFilterProp="children"
                                    onChange={onChange}
                                    filterOption={filterOption}
                                    options={ cities }
                                    />
                                </Form.Item >
                            </div>

                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>CAP sede:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'100%'}} name={ 'office_zip_code' }  rules={[{required: true, message:'Campo obbligatorio'}]}>
                                    <input  style={{ ...getColorTheme1() }} placeholder='Inserisci il cap' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>

                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Provincia:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'100%'}} name={ 'office_province' }  rules={[{required: true, message:'Campo obbligatorio'}]}>
                                    <input  style={{ ...getColorTheme1() }} placeholder='Inserisci la provincia' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>

                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Indirizzo:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'100%'}} name={ 'office_address' }  rules={[{required: true, message:'Campo obbligatorio'}]}>
                                    <input  style={{ ...getColorTheme1() }} placeholder='Inserisci l`indirizzo' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>

                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Civico sede:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'100%'}} name={ 'office_house_number' }  rules={[{required: true, message:'Campo obbligatorio'}]}>
                                    <input  style={{ ...getColorTheme1() }} placeholder='Inserisci il civico' defaultValue={ '' } className='input-mod'></input>
                                </Form.Item >
                            </div>

                            <div className='borderBottom' style={{ display:'flex', justifyContent:'start'}}>
                                <div style={{ textJustify:'center', margin:'auto 0px auto 15px', marginLeft:'10px', width:'20%', ...getColorTheme1()  }}>Nazione:</div>
                                <Form.Item style={{ margin: '0', flexDirection:'row', width:'100%'}} name={ 'office_nation' }  rules={[{required: true, message:'Campo obbligatorio'}]}>
                                    <input  style={{ ...getColorTheme1() }} disabled defaultValue={ 'IT' } className='input-mod'></input>
                                </Form.Item >
                            </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}